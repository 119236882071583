const ApiData = {
  Allergy: {
    resourceType: "Bundle",
    id: "dc482af6-dd62-460f-8cad-ba1b372539ca",
    meta: {
      lastUpdated: "2022-12-20T10:08:12.2157839-05:00",
    },
    type: "searchset",
    total: 1,
    link: [
      {
        relation: "self",
        url: "https://fhirapi.medifusion.com/AllergyIntolerance?patient=210034",
      },
    ],
    entry: [
      {
        fullUrl: "https://fhirapi.medifusion.com/AllergyIntolerance/9",
        resource: {
          resourceType: "AllergyIntolerance",
          id: "9",
          meta: {
            versionId: "1",
            lastUpdated: "2022-12-20T10:08:12.2396985-05:00",
            source: "#iLFSV7OLv0KF8dmQ",
            profile: [
              "http://hl7.org/fhir/us/core/StructureDefinition/us-core-allergyintolerance",
            ],
          },
          identifier: [
            {
              use: "official",
              system: "https://hl7.org/fhir/identifier-use",
              value: "9",
            },
          ],
          clinicalStatus: {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/allergyintolerance-clinical",
                code: "active",
                display: "Active",
              },
            ],
          },
          verificationStatus: {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/allergyintolerance-verification",
                code: "confirmed",
                display: "Confirmed",
              },
            ],
          },
          type: "allergy",
          category: ["food"],
          criticality: "low",
          code: {
            coding: [
              {
                system: "http://www.nlm.nih.gov/research/umls/rxnorm",
                code: "116995004",
                display: "Peanut agglutinin antigen",
              },
            ],
            text: "Peanut agglutinin antigen",
          },
          patient: {
            reference: "Patient/210034",
          },
          reaction: [
            {
              manifestation: [
                {
                  coding: [
                    {
                      system: "http://snomed.info/sct",
                      code: "247472004",
                      display: "Hives",
                    },
                  ],
                  text: "Hives",
                },
              ],
            },
          ],
        },
        search: {
          mode: "match",
        },
      },
    ],
  },
  "Blood Pressure": {
    resourceType: "Bundle",
    id: "07813be5-e995-4e35-8f89-8a815541c5d4",
    meta: {
      lastUpdated: "2022-12-19T06:47:57.5392201-05:00",
    },
    type: "searchset",
    total: 5,
    link: [
      {
        relation: "self",
        url: "https://fhirapi.medifusion.com/Observation/_search",
      },
    ],
    entry: [
      {
        fullUrl: "https://fhirapi.medifusion.com/Observation/21",
        resource: {
          resourceType: "Observation",
          id: "21",
          meta: {
            versionId: "1",
            lastUpdated: "2022-12-19T06:47:57.5392332-05:00",
            source: "#iLFSV7OLv0KF8dmQ",
            profile: [
              "http://hl7.org/fhir/StructureDefinition/bp",
              "http://hl7.org/fhir/StructureDefinition/vitalsigns",
            ],
          },
          status: "final",
          category: [
            {
              coding: [
                {
                  system:
                    "http://terminology.hl7.org/CodeSystem/observation-category",
                  code: "vital-signs",
                  display: "vital-signs",
                },
              ],
            },
          ],
          code: {
            coding: [
              {
                system: "http://loinc.org",
                code: "85354-9",
                display: "Blood Pressure",
              },
            ],
            text: "Blood Pressure",
          },
          subject: {
            reference: "Patient/210034",
          },
          encounter: {
            reference: "Encounter/71",
          },
          effectiveDateTime: "2022-12-02T05:25:00-05:00",
          issued: "2022-12-02T05:25:00-05:00",
          component: [
            {
              code: {
                coding: [
                  {
                    system: "http://loinc.org",
                    code: "8462-4",
                    display: "Diastolic Blood Pressure",
                  },
                ],
                text: "Diastolic Blood Pressure",
              },
              valueQuantity: {
                value: 80,
                unit: "mm[Hg]",
                system: "http://unitsofmeasure.org",
                code: "mm[Hg]",
              },
            },
            {
              code: {
                coding: [
                  {
                    system: "http://loinc.org",
                    code: "8480-6",
                    display: "Systolic Blood Pressure",
                  },
                ],
                text: "Systolic Blood Pressure",
              },
              valueQuantity: {
                value: 110,
                unit: "mm[Hg]",
                system: "http://unitsofmeasure.org",
                code: "mm[Hg]",
              },
            },
          ],
        },
        search: {
          mode: "match",
        },
      },
    ],
  },
  "Body Temperature": {
    resourceType: "Bundle",
    id: "c5b30ea9-ea62-4a62-acd9-7a3fe3089bd4",
    meta: {
      lastUpdated: "2022-12-19T06:47:55.0171677-05:00",
    },
    type: "searchset",
    total: 5,
    link: [
      {
        relation: "self",
        url: "https://fhirapi.medifusion.com/Observation/_search",
      },
    ],
    entry: [
      {
        fullUrl: "https://fhirapi.medifusion.com/Observation/21",
        resource: {
          resourceType: "Observation",
          id: "21",
          meta: {
            versionId: "1",
            lastUpdated: "2022-12-19T06:47:55.0171809-05:00",
            source: "#iLFSV7OLv0KF8dmQ",
            profile: [
              "http://hl7.org/fhir/StructureDefinition/bodytemp",
              "http://hl7.org/fhir/StructureDefinition/vitalsigns",
            ],
          },
          status: "final",
          category: [
            {
              coding: [
                {
                  system:
                    "http://terminology.hl7.org/CodeSystem/observation-category",
                  code: "vital-signs",
                  display: "vital-signs",
                },
              ],
            },
          ],
          code: {
            coding: [
              {
                system: "http://loinc.org",
                code: "8310-5",
                display: "Body Temperature",
              },
              {
                system: "http://loinc.org",
                code: "8331-1",
                display: "Oral temperature",
              },
            ],
            text: "Body Temperature",
          },
          subject: {
            reference: "Patient/210034",
          },
          encounter: {
            reference: "Encounter/71",
          },
          effectiveDateTime: "2022-12-02T05:25:00-05:00",
          issued: "2022-12-02T05:25:00-05:00",
          valueQuantity: {
            value: 99,
            unit: "Cel",
            system: "http://unitsofmeasure.org",
            code: "Cel",
          },
        },
        search: {
          mode: "match",
        },
      },
    ],
  },
  "Body Weight": {
    resourceType: "Bundle",
    id: "3298c7a7-3c86-487e-9c48-595085a92a8a",
    meta: {
      lastUpdated: "2022-12-19T06:47:59.909077-05:00",
    },
    type: "searchset",
    total: 5,
    link: [
      {
        relation: "self",
        url: "https://fhirapi.medifusion.com/Observation/_search",
      },
    ],
    entry: [
      {
        fullUrl: "https://fhirapi.medifusion.com/Observation/21",
        resource: {
          resourceType: "Observation",
          id: "21",
          meta: {
            versionId: "1",
            lastUpdated: "2022-12-19T06:47:59.909089-05:00",
            source: "#iLFSV7OLv0KF8dmQ",
            profile: [
              "http://hl7.org/fhir/StructureDefinition/bodyweight",
              "http://hl7.org/fhir/StructureDefinition/vitalsigns",
            ],
          },
          status: "final",
          category: [
            {
              coding: [
                {
                  system:
                    "http://terminology.hl7.org/CodeSystem/observation-category",
                  code: "vital-signs",
                  display: "vital-signs",
                },
              ],
            },
          ],
          code: {
            coding: [
              {
                system: "http://loinc.org",
                code: "29463-7",
                display: "Body weight",
              },
            ],
            text: "Body weight",
          },
          subject: {
            reference: "Patient/210034",
          },
          encounter: {
            reference: "Encounter/71",
          },
          effectiveDateTime: "2022-12-02T05:25:00-05:00",
          issued: "2022-12-02T05:25:00-05:00",
          valueQuantity: {
            value: 49,
            unit: "kg",
            system: "http://unitsofmeasure.org",
            code: "kg",
          },
        },
        search: {
          mode: "match",
        },
      },
    ],
  },
  "Care Plan": {
    resourceType: "Bundle",
    id: "9b095081-a1a0-45c2-982d-2705032fa05f",
    meta: {
      lastUpdated: "2022-12-20T10:09:37.7924309-05:00",
    },
    type: "searchset",
    total: 1,
    link: [
      {
        relation: "self",
        url: "https://fhirapi.medifusion.com/CarePlan/_search",
      },
    ],
    entry: [
      {
        fullUrl: "https://fhirapi.medifusion.com/CarePlan/1",
        resource: {
          resourceType: "CarePlan",
          id: "1",
          meta: {
            versionId: "1",
            lastUpdated: "2022-12-20T10:09:37.792442-05:00",
            source: "#E1VolYkq84D3M96u",
            profile: [
              "http://hl7.org/fhir/us/core/StructureDefinition/us-core-careplan",
            ],
          },
          text: {
            status: "generated",
            div: '<div xmlns="http://www.w3.org/1999/xhtml">Care plan for SNOMED-CT.<br/>Activities: <ul><li>SNOMED-CT</li><li>SNOMED-CT</li></ul><br/>Care plan is meant to treat .</div>',
          },
          status: "active",
          intent: "order",
          category: [
            {
              coding: [
                {
                  system:
                    "http://hl7.org/fhir/us/core/CodeSystem/careplan-category",
                  code: "assess-plan",
                },
              ],
            },
            {
              coding: [
                {
                  system: "http://snomed.info/sct",
                  code: "1001000221103",
                  display: "Inactivated cholera vaccine in oral dose form",
                },
              ],
              text: "Inactivated cholera vaccine in oral dose form",
            },
          ],
          subject: {
            reference: "Patient/210034",
          },
          encounter: {
            reference: "Encounter/71",
          },
          period: {
            start: "2022-12-05T22:20:23-05:00",
          },
          careTeam: [
            {
              reference: "6",
            },
          ],
        },
        search: {
          mode: "match",
        },
      },
    ],
  },
  "Care Team": {
    resourceType: "Bundle",
    id: "e10dbf24-aa9a-423b-9b94-a8e180331a24",
    meta: {
      lastUpdated: "2022-12-20T10:10:17.5553734-05:00",
    },
    type: "searchset",
    total: 5,
    link: [
      {
        relation: "self",
        url: "https://fhirapi.medifusion.com/CareTeam/_search",
      },
    ],
    entry: [
      {
        fullUrl: "https://fhirapi.medifusion.com/CareTeam/6",
        resource: {
          resourceType: "CareTeam",
          id: "6",
          meta: {
            versionId: "1",
            lastUpdated: "2022-12-20T10:10:17.5553879-05:00",
            source: "#E1VolYkq84D3M96u",
            profile: [
              "http://hl7.org/fhir/us/core/StructureDefinition/us-core-careteam",
            ],
          },
          status: "active",
          subject: {
            reference: "Patient/210034",
          },
          encounter: {
            reference: "Encounter/71",
          },
          period: {
            start: "0001-01-01T00:00:00-05:17",
          },
          participant: [
            {
              role: [
                {
                  coding: [
                    {
                      system: "http://snomed.info/sct",
                      code: "223366009",
                      display: "Healthcare professional (occupation)",
                    },
                  ],
                  text: "Healthcare professional (occupation)",
                },
              ],
              member: {
                reference: "Practitioner/2",
                display: "John Smith",
              },
            },
            {
              role: [
                {
                  coding: [
                    {
                      system: "http://snomed.info/sct",
                      code: "116154003",
                      display: "Patient",
                    },
                  ],
                  text: "Patient",
                },
              ],
              member: {
                reference: "Patient/210034",
                display: "InfernoPatLName InfernoPatFName",
              },
            },
            {
              role: [
                {
                  coding: [
                    {
                      system: "http://snomed.info/sct",
                      code: "116154003",
                      display: "Healthcare services (qualifier value)",
                    },
                  ],
                  text: "Healthcare services (qualifier value)",
                },
              ],
              member: {
                reference: "Organization/2",
                display: "ELEVATED HEALTH INC",
              },
            },
          ],
          reasonCode: [
            {
              coding: [
                {
                  system: "http://icd.info/icd",
                  code: "E08.0",
                  display:
                    "Diabetes mellitus due to underlying condition with hyperosmolarity",
                },
              ],
            },
          ],
          managingOrganization: [
            {
              reference: "Organization/2",
              display: "ELEVATED HEALTH INC",
            },
          ],
        },
        search: {
          mode: "match",
        },
      },
    ],
  },
  Condition: {
    resourceType: "Bundle",
    id: "9cdd2486-9577-48af-bf77-297d038e99fa",
    meta: {
      lastUpdated: "2022-12-20T10:10:42.9304378-05:00",
    },
    type: "searchset",
    total: 5,
    link: [
      {
        relation: "self",
        url: "https://fhirapi.medifusion.com/Condition/_search",
      },
    ],
    entry: [
      {
        fullUrl: "https://fhirapi.medifusion.com/Condition/21",
        resource: {
          resourceType: "Condition",
          id: "21",
          meta: {
            versionId: "1",
            lastUpdated: "2022-12-20T10:10:42.9334846-05:00",
            source: "#iLFSV7OLv0KF8dmQ",
            profile: [
              "http://hl7.org/fhir/us/core/StructureDefinition/us-core-condition",
            ],
          },
          clinicalStatus: {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/condition-clinical",
                code: "active",
              },
            ],
          },
          verificationStatus: {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/condition-ver-status",
                code: "confirmed",
              },
            ],
          },
          category: [
            {
              coding: [
                {
                  system:
                    "http://terminology.hl7.org/CodeSystem/condition-category",
                  code: "encounter-diagnosis",
                  display: "Encounter Diagnosis",
                },
              ],
            },
          ],
          code: {
            coding: [
              {
                system: "http://icd.info/icd",
                code: "E08.0",
                display:
                  "Diabetes mellitus due to underlying condition with hyperosmolarity",
              },
            ],
            text: "Diabetes mellitus due to underlying condition with hyperosmolarity",
          },
          subject: {
            reference: "Patient/210034",
          },
          encounter: {
            reference: "Encounter/75",
          },
          onsetString: "2022-12-02T05:25:00-05:00",
          recordedDate: "2022-12-02T05:25:00-05:00",
        },
        search: {
          mode: "match",
        },
      },
    ],
  },
  Device: {
    resourceType: "Bundle",
    id: "a1316f5e-c3e8-42b2-bd79-e359d92adff7",
    meta: {
      lastUpdated: "2022-12-20T10:12:51.3344088-05:00",
    },
    total: 1,
    link: [
      {
        relation: "self",
        url: "https://inferno.healthit.gov/reference-server/r4/Device?patient=210034",
      },
    ],
    entry: [
      {
        fullUrl: "https://inferno.healthit.gov/reference-server/r4/Device/1",
        resource: {
          resourceType: "Device",
          id: "1",
          meta: {
            versionId: "1",
            lastUpdated: "2022-12-20T10:12:51.3370745-05:00",
            source: "#iLFSV7OLv0KF8dmQ",
            profile: [
              "http://hl7.org/fhir/us/core/StructureDefinition/us-core-implantable-device",
            ],
          },
          udiCarrier: [
            {
              deviceIdentifier: "00643169007222",
              carrierHRF: "(01)00643169007222(17)160128(21)BLC200461H",
            },
          ],
          status: "inactive",
          distinctIdentifier: "00643169007222",
          manufactureDate: "1990-01-02",
          expirationDate: "2016-01-28",
          lotNumber: "False",
          serialNumber: "BLC200461H",
          deviceName: [
            {
              name: "Cardiac resynchronization therapy implantable pacemaker",
              type: "user-friendly-name",
            },
          ],
          type: {
            coding: [
              {
                system: "http://snomed.info/sct",
                code: "704708004",
                display:
                  "Cardiac resynchronization therapy implantable pacemaker",
              },
            ],
            text: "Cardiac resynchronization therapy implantable pacemaker",
          },
          patient: {
            reference: "Patient/210034",
          },
        },
        search: {
          mode: "match",
        },
      },
    ],
  },
  "Diag Report": {
    resourceType: "Bundle",
    id: "a1c9f6b7-91f7-4e1a-a54a-853605260f66",
    meta: {
      lastUpdated: "2022-12-20T10:13:52.4371383-05:00",
    },
    type: "searchset",
    total: 1,
    link: [
      {
        relation: "self",
        url: "https://fhirapi.medifusion.com/DiagnosticReport/_search",
      },
    ],
    entry: [
      {
        fullUrl: "https://fhirapi.medifusion.com/DiagnosticReport/4-d",
        resource: {
          resourceType: "DiagnosticReport",
          id: "4-d",
          meta: {
            versionId: "1",
            lastUpdated: "2022-12-20T10:13:52.4371513-05:00",
            source: "#iLFSV7OLv0KF8dmQ",
            profile: [
              "http://hl7.org/fhir/us/core/StructureDefinition/us-core-diagnosticreport-note",
            ],
          },
          status: "final",
          category: [
            {
              coding: [
                {
                  system: "http://loinc.org",
                  code: "LP29684-5",
                  display: "Radiology",
                },
              ],
            },
          ],
          code: {
            coding: [
              {
                system: "http://loinc.org",
                code: "11488-4",
                display: "Consultation note",
              },
            ],
          },
          subject: {
            reference: "Patient/210034",
          },
          encounter: {
            reference: "Encounter/71",
          },
          effectiveDateTime: "2022-12-02T00:00:00-05:00",
          issued: "2022-12-02T00:00:00-05:00",
          performer: [
            {
              reference: "Practitioner/4",
              display: "MATTHEW ABINANTE",
            },
            {
              reference: "Practitioner/4",
            },
            {
              reference: "Organization/2",
            },
          ],
          presentedForm: [
            {
              contentType: "text/plain",
              data: "MS8xLzIwMDAgMTI6MDA6MDAgQU0KIyBDaGllZiBDb21wbGFpbnQKLEZldmVyCiMgSGlzdG9yeSBvZiBQcmVzZW50IElsbG5lc3MKTm8gSGlzdG9yeSBvZiBQcmVzZW50IElsbG5lc3MuCiMgU29jaWFsIEhpc3RvcnkKUGF0aWVudCBpcyBQYXRpZW50IGhhcyBzbW9raW5nIFN0YXR1cyBDdXJyZW50IGV2ZXJ5IGRheSBzbW9rZXJQYXRpZW50IGhhcyBBbGNvaG9sVXNlIFN0YXR1cyBQYXRpZW50IGhhcyBEcnVnVXNlIFN0YXR1cyAKIyBBbGxlcmdpZXMKUGVhbnV0IGFnZ2x1dGluaW4gYW50aWdlbiwKIyBNZWRpY2F0aW9ucwpBdWdtZW50aW4gSW5qZWN0YWJsZSBQcm9kdWN0LAojIEFzc2Vzc21lbnQgYW5kIFBsYW4KTm8gQXNzZXNzbWVudCBhbmQgUGxhbi4KIyBQbGFuCkluYWN0aXZhdGVkIGNob2xlcmEgdmFjY2luZSBpbiBvcmFsIGRvc2UgZm9ybSw=",
            },
          ],
        },
        search: {
          mode: "match",
        },
      },
    ],
  },
  "Document Ref": {
    resourceType: "Bundle",
    id: "44c617d7-6ea2-405c-a880-c9bb3cfab23c",
    meta: {
      lastUpdated: "2022-12-20T10:14:17.5525043-05:00",
    },
    type: "searchset",
    total: 5,
    link: [
      {
        relation: "self",
        url: "https://fhirapi.medifusion.com/DocumentReference/_search",
      },
    ],
    entry: [
      {
        fullUrl: "https://fhirapi.medifusion.com/DocumentReference/71",
        resource: {
          resourceType: "DocumentReference",
          id: "71",
          meta: {
            versionId: "1",
            lastUpdated: "2022-12-20T10:14:17.5525176-05:00",
            source: "iLFSV7OLv0KF8dmQ",
            profile: [
              "http://hl7.org/fhir/us/core/StructureDefinition/us-core-documentreference",
            ],
          },
          identifier: [
            {
              use: "official",
              system: "https://hl7.org/fhir/Identifier-use",
              value: "71",
            },
          ],
          status: "current",
          type: {
            coding: [
              {
                system: "http://loinc.org",
                code: "11488-4",
                display: "consultation note",
              },
            ],
            text: "consultation note",
          },
          category: [
            {
              coding: [
                {
                  system: "http://loinc.org",
                  code: "clinical-note",
                  display: "Clinical Note",
                },
              ],
            },
          ],
          subject: {
            reference: "Patient/210034",
          },
          date: "2022-12-02T05:25:00-05:00",
          author: [
            {
              reference: "Practitioner/4",
              display: "MATTHEW ABINANTE",
            },
          ],
          custodian: {
            reference: "Organization/2",
            display: "ELEVATED HEALTH INC",
          },
          content: [
            {
              attachment: {
                contentType: "text/plain",
                data: "MS8xLzIwMDAgMTI6MDA6MDAgQU0KIyBDaGllZiBDb21wbGFpbnQKLEZldmVyCiMgSGlzdG9yeSBvZiBQcmVzZW50IElsbG5lc3MKTm8gSGlzdG9yeSBvZiBQcmVzZW50IElsbG5lc3MuCiMgU29jaWFsIEhpc3RvcnkKUGF0aWVudCBpcyBQYXRpZW50IGhhcyBzbW9raW5nIFN0YXR1cyBDdXJyZW50IGV2ZXJ5IGRheSBzbW9rZXJQYXRpZW50IGhhcyBBbGNvaG9sVXNlIFN0YXR1cyBQYXRpZW50IGhhcyBEcnVnVXNlIFN0YXR1cyAKIyBBbGxlcmdpZXMKUGVhbnV0IGFnZ2x1dGluaW4gYW50aWdlbiwKIyBNZWRpY2F0aW9ucwpBdWdtZW50aW4gSW5qZWN0YWJsZSBQcm9kdWN0LAojIEFzc2Vzc21lbnQgYW5kIFBsYW4KTm8gQXNzZXNzbWVudCBhbmQgUGxhbi4KIyBQbGFuCkluYWN0aXZhdGVkIGNob2xlcmEgdmFjY2luZSBpbiBvcmFsIGRvc2UgZm9ybSw=",
              },
              format: {
                system: "urn:oid:1.3.6.1.4.1.19376.1.2.3",
                code: "urn:ihe:iti:xds:2017:mimeTypeSufficient",
                display: "mimeType Sufficient",
              },
            },
          ],
          context: {
            encounter: [
              {
                reference: "Encounter/71",
              },
            ],
            period: {
              start: "2022-12-02T05:25:00-05:00",
              end: "2022-12-02T05:25:00-05:00",
            },
          },
        },
        search: {
          mode: "match",
        },
      },
    ],
  },
  Encounter: {
    resourceType: "Encounter",
    id: "71",
    meta: {
      versionId: "1",
      lastUpdated: "2022-12-19T06:48:08.2328825-05:00",
      source: "#iLFSV7OLv0KF8dmQ",
      profile: [
        "http://hl7.org/fhir/us/core/StructureDefinition/us-core-encounter",
      ],
    },
    identifier: [
      {
        use: "official",
        system: "https://hl7.org/fhir/Identifier-use",
        value: "71",
      },
    ],
    status: "in-progress",
    class: {
      system: "http://terminology.hl7.org/CodeSystem/v3-ActCode",
      code: "AMB",
    },
    type: [
      {
        coding: [
          {
            system: "http://icd.info/icd",
            code: "E08.0",
            display:
              "Diabetes mellitus due to underlying condition with hyperosmolarity",
          },
        ],
        text: "Diabetes mellitus due to underlying condition with hyperosmolarity",
      },
    ],
    subject: {
      reference: "Patient/210034",
      display: "InfernoPatFName InfernoPatLName",
    },
    participant: [
      {
        type: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/v3-ParticipationType",
                code: "162673000",
                display: "PCP",
              },
            ],
            text: "PCP",
          },
        ],
        period: {
          start: "2022-12-02T05:25:00-05:00",
          end: "2022-12-02T05:25:00-05:00",
        },
        individual: {
          reference: "Practitioner/4",
          display: "MATTHEW ABINANTE",
        },
      },
    ],
    period: {
      start: "2022-12-02T05:25:00-05:00",
      end: "2022-12-02T05:25:00-05:00",
    },
    reasonCode: [
      {
        coding: [
          {
            system: "http://icd.info/icd",
            code: "E08.0",
            display:
              "Diabetes mellitus due to underlying condition with hyperosmolarity",
          },
        ],
        text: "Diabetes mellitus due to underlying condition with hyperosmolarity",
      },
    ],
    location: [
      {
        location: {
          reference: "Location/12",
          display: "ELEVATED HEALTH",
        },
      },
    ],
    serviceProvider: {
      reference: "Organization/2",
      display: "ELEVATED HEALTH INC",
    },
  },
  Goal: {
    resourceType: "Bundle",
    id: "23c1faf9-b022-4403-ad40-136c235ac2c6",
    meta: {
      lastUpdated: "2022-12-20T10:14:44.0410762-05:00",
    },
    link: [
      {
        relation: "self",
        url: "https://fhirapi.medifusion.com/Goal/_search",
      },
    ],
    entry: [
      {
        fullUrl: "https://fhirapi.medifusion.com/Goal/2",
        resource: {
          resourceType: "Goal",
          id: "2",
          meta: {
            versionId: "1",
            lastUpdated: "2022-12-20T10:14:44.0438676-05:00",
            source: "#iLFSV7OLv0KF8dmQ",
            profile: [
              "http://hl7.org/fhir/us/core/StructureDefinition/us-core-goal",
            ],
          },
          lifecycleStatus: "active",
          description: {
            text: "Need to gain more energy to do regular activities. (Visual Inspection)",
          },
          subject: {
            reference: "Patient/210034",
          },
          target: [
            {
              dueDate: "2022-12-05",
            },
          ],
        },
        search: {
          mode: "match",
        },
      },
    ],
  },
  "Head Occipital-frontal Circumference": {
    resourceType: "Bundle",
    id: "9f1c3451-b435-4802-9534-e4b68ab85cba",
    meta: {
      lastUpdated: "2022-12-19T06:47:50.309-05:00",
    },
    type: "searchset",
    total: 5,
    link: [
      {
        relation: "self",
        url: "https://fhirapi.medifusion.com/Observation/_search",
      },
    ],
    entry: [
      {
        fullUrl: "https://fhirapi.medifusion.com/Observation/21",
        resource: {
          resourceType: "Observation",
          id: "21",
          meta: {
            versionId: "1",
            lastUpdated: "2022-12-19T06:47:50.3090133-05:00",
            source: "#iLFSV7OLv0KF8dmQ",
            profile: [
              "http://hl7.org/fhir/StructureDefinition/vitalsigns",
              "http://hl7.org/fhir/StructureDefinition/head-occipital-frontal-circumference-percentile",
            ],
          },
          status: "final",
          category: [
            {
              coding: [
                {
                  system:
                    "http://terminology.hl7.org/CodeSystem/observation-category",
                  code: "vital-signs",
                  display: "vital-signs",
                },
              ],
            },
          ],
          code: {
            coding: [
              {
                system: "http://loinc.org",
                code: "8289-1",
                display: "Head Occipital-frontal circumference Percentile",
              },
            ],
            text: "Head Occipital-frontal circumference Percentile",
          },
          subject: {
            reference: "Patient/210034",
          },
          encounter: {
            reference: "Encounter/71",
          },
          effectiveDateTime: "2022-12-02T05:25:00-05:00",
          issued: "2022-12-02T05:25:00-05:00",
          valueQuantity: {
            value: 30,
            unit: "%",
            system: "http://unitsofmeasure.org",
            code: "%",
          },
        },
        search: {
          mode: "match",
        },
      },
    ],
  },
  "Heart Rate": {
    resourceType: "Bundle",
    id: "9c28825f-7855-4a35-80b2-6c630e2088a9",
    meta: {
      lastUpdated: "2022-12-19T06:48:02.3742236-05:00",
    },
    type: "searchset",
    total: 5,
    link: [
      {
        relation: "self",
        url: "https://fhirapi.medifusion.com/Observation/_search",
      },
    ],
    entry: [
      {
        fullUrl: "https://fhirapi.medifusion.com/Observation/21",
        resource: {
          resourceType: "Observation",
          id: "21",
          meta: {
            versionId: "1",
            lastUpdated: "2022-12-19T06:48:02.3742372-05:00",
            source: "#iLFSV7OLv0KF8dmQ",
            profile: [
              "http://hl7.org/fhir/StructureDefinition/heartrate",
              "http://hl7.org/fhir/StructureDefinition/vitalsigns",
            ],
          },
          status: "final",
          category: [
            {
              coding: [
                {
                  system:
                    "http://terminology.hl7.org/CodeSystem/observation-category",
                  code: "vital-signs",
                  display: "vital-signs",
                },
              ],
            },
          ],
          code: {
            coding: [
              {
                system: "http://loinc.org",
                code: "8867-4",
                display: "Heart rate",
              },
            ],
            text: "Heart rate",
          },
          subject: {
            reference: "Patient/210034",
          },
          encounter: {
            reference: "Encounter/71",
          },
          effectiveDateTime: "2022-12-02T05:25:00-05:00",
          issued: "2022-12-02T05:25:00-05:00",
          valueQuantity: {
            value: 100,
            unit: "/min",
            system: "http://unitsofmeasure.org",
            code: "/min",
          },
        },
        search: {
          mode: "match",
        },
      },
    ],
  },
  Immunization: {
    resourceType: "Bundle",
    id: "3c406e23-7c01-498f-9520-eadfd40977d9",
    meta: {
      lastUpdated: "2022-12-20T10:15:05.9930062-05:00",
    },
    type: "searchset",
    total: 1,
    link: [
      {
        relation: "self",
        url: "https://fhirapi.medifusion.com/Immunization?patient=210034",
      },
    ],
    entry: [
      {
        fullUrl: "https://fhirapi.medifusion.com/Immunization/8",
        resource: {
          resourceType: "Immunization",
          id: "8",
          meta: {
            versionId: "1",
            lastUpdated: "2022-12-20T10:15:05.9955452-05:00",
            source: "#iLFSV7OLv0KF8dmQ",
            profile: [
              "http://hl7.org/fhir/us/core/StructureDefinition/us-core-immunization",
            ],
          },
          status: "completed",
          statusReason: {
            coding: [
              {
                system: "http://terminology.hl7.org/CodeSystem/v3-ActReason",
                code: "IMMUNE",
                display: "immunity",
              },
            ],
          },
          vaccineCode: {
            coding: [
              {
                system: "http://hl7.org/fhir/sid/cvx",
                code: "160",
                display: "Influenza A monovalent (H",
              },
              {
                system: "http://hl7.org/fhir/sid/ndc",
                code: "58160-0802-02",
                display: "Influenza A monovalent (H",
              },
            ],
            text: "Influenza A monovalent (H",
          },
          patient: {
            reference: "Patient/210034",
          },
          occurrenceDateTime: "2022-12-02",
          primarySource: true,
          location: {
            reference: "Location/12",
            display: "ELEVATED HEALTH",
          },
        },
        search: {
          mode: "match",
        },
      },
    ],
  },
  "Laboratory Result Observation": {
    resourceType: "Bundle",
    id: "9e6d4be7-2cce-4e7e-9939-2df45c0b81c6",
    meta: {
      lastUpdated: "2022-12-19T06:47:44.309158-05:00",
    },
    type: "searchset",
    total: 1,
    link: [
      {
        relation: "self",
        url: "https://fhirapi.medifusion.com/Observation/_search",
      },
    ],
    entry: [
      {
        fullUrl: "https://fhirapi.medifusion.com/Observation/2-r",
        resource: {
          resourceType: "Observation",
          id: "2-r",
          meta: {
            versionId: "1",
            lastUpdated: "2022-12-19T06:47:44.3091672-05:00",
            source: "#iLFSV7OLv0KF8dmQ",
            profile: [
              "http://hl7.org/fhir/us/core/StructureDefinition/us-core-observation-lab",
            ],
          },
          status: "final",
          category: [
            {
              coding: [
                {
                  system:
                    "http://terminology.hl7.org/CodeSystem/observation-category",
                  code: "laboratory",
                  display: "laboratory",
                },
              ],
            },
          ],
          code: {
            coding: [
              {
                system: "http://loinc.org",
                code: "17774-1",
                display: "Yellow Dye IgE Qn",
              },
            ],
            text: "Yellow Dye IgE Qn",
          },
          subject: {
            reference: "Patient/210034",
          },
          encounter: {
            reference: "Encounter/71",
          },
          effectiveDateTime: "2022-12-02T05:25:00-05:00",
          issued: "2022-12-02T05:25:00-05:00",
          valueQuantity: {
            value: 10,
            unit: "%{Bound}",
            system: "http://unitsofmeasure.org",
            code: "%{Bound}",
          },
        },
        search: {
          mode: "match",
        },
      },
    ],
  },
  "Medication Req": {
    resourceType: "Bundle",
    id: "f04a5a54-4d62-4dbf-8922-3ceb4727bfb6",
    meta: {
      lastUpdated: "2022-12-20T10:15:51.2915552-05:00",
    },
    type: "searchset",
    total: 1,
    entry: [
      {
        fullUrl: "https://fhirapi.medifusion.com/Observation/7-a",
        resource: {
          resourceType: "MedicationRequest",
          id: "7-a",
          meta: {
            versionId: "1",
            lastUpdated: "2022-12-20T10:15:51.2915676-05:00",
            source: "#iLFSV7OLv0KF8dmQ",
            profile: [
              "http://hl7.org/fhir/us/core/StructureDefinition/us-core-medicationrequest",
            ],
          },
          status: "active",
          intent: "order",
          reportedReference: {
            reference: "Patient/210034",
          },
          medicationReference: {
            reference: "Medication/7",
          },
          subject: {
            reference: "Patient/210034",
          },
          encounter: {
            reference: "Encounter/71",
          },
          authoredOn: "2022-12-02T05:25:00-05:00",
          requester: {
            reference: "Practitioner/4",
            display: "MATTHEW ABINANTE",
          },
          dosageInstruction: [
            {
              text: "1 tablet daily",
              asNeededBoolean: true,
            },
          ],
        },
        search: {
          mode: "match",
        },
      },
    ],
  },
  "Observation Body Height": {
    resourceType: "Bundle",
    id: "0363ebea-e25b-4a05-a728-7932e373bd71",
    meta: {
      lastUpdated: "2022-12-19T06:47:52.655556-05:00",
    },
    type: "searchset",
    total: 5,
    link: [
      {
        relation: "self",
        url: "https://fhirapi.medifusion.com/Observation/_search",
      },
    ],
    entry: [
      {
        fullUrl: "https://fhirapi.medifusion.com/Observation/21",
        resource: {
          resourceType: "Observation",
          id: "21",
          meta: {
            versionId: "1",
            lastUpdated: "2022-12-19T06:47:52.6555682-05:00",
            source: "#iLFSV7OLv0KF8dmQ",
            profile: [
              "http://hl7.org/fhir/StructureDefinition/bodyheight",
              "http://hl7.org/fhir/StructureDefinition/vitalsigns",
            ],
          },
          status: "final",
          category: [
            {
              coding: [
                {
                  system:
                    "http://terminology.hl7.org/CodeSystem/observation-category",
                  code: "vital-signs",
                  display: "vital-signs",
                },
              ],
            },
          ],
          code: {
            coding: [
              {
                system: "http://loinc.org",
                code: "8302-2",
                display: "Body Height",
              },
            ],
            text: "Body Height",
          },
          subject: {
            reference: "Patient/210034",
          },
          encounter: {
            reference: "Encounter/71",
          },
          effectiveDateTime: "2022-12-02T05:25:00-05:00",
          issued: "2022-12-02T05:25:00-05:00",
          valueQuantity: {
            value: 162.6,
            unit: "cm",
            system: "http://unitsofmeasure.org",
            code: "cm",
          },
        },
        search: {
          mode: "match",
        },
      },
    ],
  },
  Organization: {
    resourceType: "Organization",
    id: "2",
    meta: {
      versionId: "1",
      lastUpdated: "2022-12-19T06:48:08.6936344-05:00",
      source: "#E1VolYkq84D3M96u",
      profile: [
        "http://hl7.org/fhir/us/core/StructureDefinition/us-core-organization",
      ],
    },
    identifier: [
      {
        system: "https://github.com/synthetichealth/synthea",
        value: "1255910519",
      },
      {
        system: "http://hl7.org/fhir/sid/us-npi",
        value: "1255910519",
      },
    ],
    active: false,
    type: [
      {
        coding: [
          {
            system: "http://terminology.hl7.org/CodeSystem/organization-type",
            code: "prov",
            display: "Healthcare Provider",
          },
        ],
        text: "Healthcare Provider",
      },
    ],
    name: "Medifusion HEALTH INC",
    telecom: [
      {
        system: "phone",
        value: "7144012345",
      },
    ],
    address: [
      {
        line: ["1st ave ST STE 800"],
        city: "HUNTINGTON BEACH",
        state: "CA",
        postalCode: "92648",
        country: "United States of America",
      },
    ],
  },
  Patient: {
    resourceType: "Bundle",
    id: "fb2c9189-9f28-4ff1-8e4f-6f4f6c717730",
    meta: {
      lastUpdated: "2022-12-20T08:58:09.5139749-05:00",
    },
    entry: [
      {
        resource: {
          resourceType: "Patient",
          id: "210034",
          meta: {
            versionId: "1",
            lastUpdated: "2022-12-20T08:58:09.5139832-05:00",
            source: "#iLFSV7OLv0KF8dmQ",
            profile: [
              "http://hl7.org/fhir/us/core/StructureDefinition/us-core-patient",
            ],
          },
          text: {
            status: "generated",
            div: '<div xmlns="http://www.w3.org/1999/xhtml">Generated by <a href="https://github.com/synthetichealth/synthea">Synthea</a>.Version identifier: v2.6.1-3-g50f4f58f\n .   Person seed: 4286870567281389426  Population seed: 3</div>',
          },
          extension: [
            {
              extension: [
                {
                  url: "ombCategory",
                  valueCoding: {
                    system: "urn:oid:2.16.840.1.113883.6.238",
                    code: "2106-3",
                    display: "White",
                  },
                },
                {
                  url: "text",
                  valueString: "White",
                },
              ],
              url: "http://hl7.org/fhir/us/core/StructureDefinition/us-core-race",
            },
            {
              extension: [
                {
                  url: "ombCategory",
                  valueCoding: {
                    system: "urn:oid:2.16.840.1.113883.6.238",
                    code: "2135-2",
                    display: "HISPANIC OR LATINO",
                  },
                },
                {
                  url: "text",
                  valueString: "HISPANIC OR LATINO",
                },
              ],
              url: "http://hl7.org/fhir/us/core/StructureDefinition/us-core-ethnicity",
            },
            {
              url: "http://hl7.org/fhir/StructureDefinition/patient-mothersMaidenName",
              valueString: ",",
            },
            {
              url: "http://hl7.org/fhir/us/core/StructureDefinition/us-core-birthsex",
              valueCode: "M",
            },
            {
              url: "http://hl7.org/fhir/StructureDefinition/patient-birthPlace",
              valueAddress: {
                city: "NAPA",
                state: "CA",
                country: "United States of America",
              },
            },
          ],
          identifier: [
            {
              system: "https://ehr.medifusion.com",
              value: "210034",
            },
            {
              type: {
                coding: [
                  {
                    system: "http://terminology.hl7.org/CodeSystem/v2-0203",
                    code: "MR",
                    display: "Medical Record Number",
                  },
                ],
                text: "Medical Record Number",
              },
              system: "https://ehr.medifusion.com/",
              value: "210034",
            },
          ],
          name: [
            {
              use: "official",
              text: "InfernoPatLName,InfernoPatFName",
              family: "InfernoPatLName",
              given: ["InfernoPatFName", "S"],
            },
          ],
          telecom: [
            {
              system: "phone",
              value: "0102154545",
              use: "mobile",
            },
            {
              system: "phone",
              value: "9843446464",
              use: "home",
            },
            {
              system: "email",
              value: "Test@medifusion.com",
              use: "work",
            },
          ],
          gender: "male",
          birthDate: "2000-01-01",
          address: [
            {
              use: "home",
              type: "postal",
              text: "636 MADISON ST  NAPA CA 94559",
              line: ["636 MADISON ST"],
              city: "NAPA",
              state: "CA",
              postalCode: "94559",
              country: "United States of America",
              period: {
                start: "2022-12-20",
              },
            },
            {
              use: "old",
              type: "postal",
              text: "Test address   Milwaukee WI 53210",
              line: ["Test address"],
              city: "Milwaukee",
              state: "WI",
              postalCode: "53210",
              country: "USA",
              period: {
                start: "2022-12-20",
                end: "2022-12-20",
              },
            },
          ],
          maritalStatus: {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/v3-MaritalStatus",
                code: "UNK",
                display: "unknown",
              },
            ],
          },
          communication: [
            {
              language: {
                coding: [
                  {
                    system: "urn:ietf:bcp:47",
                    code: "en-US",
                    display: "English",
                  },
                ],
              },
              preferred: true,
            },
          ],
        },
      },
    ],
  },
  "Pediatric BMI For Age Observation": {
    resourceType: "Bundle",
    id: "03b9f119-64f3-4040-9f2f-e4495285fa99",
    meta: {
      lastUpdated: "2022-12-19T06:47:45.2096285-05:00",
    },
    type: "searchset",
    total: 5,
    link: [
      {
        relation: "self",
        url: "https://fhirapi.medifusion.com/Observation/_search",
      },
    ],
    entry: [
      {
        fullUrl: "https://fhirapi.medifusion.com/Observation/21",
        resource: {
          resourceType: "Observation",
          id: "21",
          meta: {
            versionId: "1",
            lastUpdated: "2022-12-19T06:47:45.2096394-05:00",
            source: "#iLFSV7OLv0KF8dmQ",
            profile: [
              "http://hl7.org/fhir/StructureDefinition/pediatric-bmi-for-age",
            ],
          },
          status: "final",
          category: [
            {
              coding: [
                {
                  system:
                    "http://terminology.hl7.org/CodeSystem/observation-category",
                  code: "vital-signs",
                  display: "vital-signs",
                },
              ],
            },
          ],
          code: {
            coding: [
              {
                system: "http://loinc.org",
                code: "59576-9",
                display:
                  "Body mass index (BMI) [Percentile] Per age and gender",
              },
            ],
            text: "Body mass index (BMI) [Percentile] Per age and gender",
          },
          subject: {
            reference: "Patient/210034",
          },
          encounter: {
            reference: "Encounter/71",
          },
          effectiveDateTime: "2022-12-02T05:25:00-05:00",
          issued: "2022-12-02T05:25:00-05:00",
          valueQuantity: {
            value: 24,
            unit: "%",
            system: "http://unitsofmeasure.org",
            code: "%",
          },
        },
        search: {
          mode: "match",
        },
      },
    ],
  },
  Practitioner: {
    resourceType: "Practitioner",
    id: "4",
    meta: {
      versionId: "1",
      lastUpdated: "2022-12-19T06:48:08.7890099-05:00",
      source: "#iLFSV7OLv0KF8dmQ",
      profile: [
        "http://hl7.org/fhir/us/core/StructureDefinition/us-core-practitioner",
      ],
    },
    identifier: [
      {
        system: "http://hl7.org/fhir/sid/us-npi",
        value: "0123450000",
      },
    ],
    active: false,
    name: [
      {
        family: "Demo",
        given: ["Smith"],
        prefix: ["Mr"],
      },
    ],
    telecom: [
      {
        extension: [
          {
            url: "http://hl7.org/fhir/us/core/StructureDefinition/us-core-direct",
            valueBoolean: true,
          },
        ],
        system: "email",
        value: "demo@gmail.com",
        use: "work",
      },
    ],
    address: [
      {
        line: ["25 Ave North Block"],
        city: "NEW YORK",
        state: "NY",
        postalCode: "10007",
        country: "United States of America",
      },
    ],
    gender: "male",
  },

  "Pediatric Weight for height observation": {
    resourceType: "Bundle",
    id: "87fc2a00-5e60-4a86-80b1-4904f21129b2",
    meta: {
      lastUpdated: "2022-12-19T06:47:41.916113-05:00",
    },
    type: "searchset",
    total: 5,
    link: [
      {
        relation: "self",
        url: "https://fhirapi.medifusion.com/Observation/_search",
      },
    ],
    entry: [
      {
        fullUrl: "https://fhirapi.medifusion.com/Observation/21",
        resource: {
          resourceType: "Observation",
          id: "21",
          meta: {
            versionId: "1",
            lastUpdated: "2022-12-19T06:47:41.9161267-05:00",
            source: "#iLFSV7OLv0KF8dmQ",
            profile: [
              "http://hl7.org/fhir/StructureDefinition/pediatric-weight-for-height",
            ],
          },
          status: "final",
          category: [
            {
              coding: [
                {
                  system:
                    "http://terminology.hl7.org/CodeSystem/observation-category",
                  code: "vital-signs",
                  display: "vital-signs",
                },
              ],
            },
          ],
          code: {
            coding: [
              {
                system: "http://loinc.org",
                code: "77606-2",
                display: "Weight-for-length Per age and sex",
              },
            ],
            text: "Weight-for-length Per age and sex",
          },
          subject: {
            reference: "Patient/210034",
          },
          encounter: {
            reference: "Encounter/71",
          },
          effectiveDateTime: "2022-12-02T05:25:00-05:00",
          issued: "2022-12-02T05:25:00-05:00",
          valueQuantity: {
            value: 25,
            unit: "%",
            system: "http://unitsofmeasure.org",
            code: "%",
          },
        },
        search: {
          mode: "match",
        },
      },
    ],
  },
  Procedure: {
    resourceType: "Bundle",
    id: "e24ce317-0e5e-4d0d-a526-6620109c5743",
    meta: {
      lastUpdated: "2022-12-19T06:48:07.4689337-05:00",
    },
    type: "searchset",
    total: 5,
    link: [
      {
        relation: "self",
        url: "https://fhirapi.medifusion.com/Procedure?patient=210034",
      },
    ],
    entry: [
      {
        fullUrl: "https://fhirapi.medifusion.com/Procedure/10",
        resource: {
          resourceType: "Procedure",
          id: "10",
          meta: {
            versionId: "1",
            lastUpdated: "2022-12-19T06:48:07.4739365-05:00",
            source: "#iLFSV7OLv0KF8dmQ",
            profile: [
              "http://hl7.org/fhir/us/core/StructureDefinition/us-core-procedure",
            ],
          },
          status: "in-progress",
          code: {
            coding: [
              {
                system: "http://snomed.info/sct",
                code: "99212",
                display:
                  'Office or other outpatient visit for the evaluation and management of an established patient, which requires at least 2 of these 3 key components: A problem focused history; A problem focused examination; Straightforward medical decision making. Counseling and/or coordination of care with other physicians, other qualified health care professionals, or agencies are provided consistent with the nature of the problem(s) and the patient"s and/or family"s needs. Usually, the presenting problem(s) are self limited or minor. Typically, 10 minutes are spent face-to-face with the patient and/or family.',
              },
            ],
            text: 'Office or other outpatient visit for the evaluation and management of an established patient, which requires at least 2 of these 3 key components: A problem focused history; A problem focused examination; Straightforward medical decision making. Counseling and/or coordination of care with other physicians, other qualified health care professionals, or agencies are provided consistent with the nature of the problem(s) and the patient"s and/or family"s needs. Usually, the presenting problem(s) are self limited or minor. Typically, 10 minutes are spent face-to-face with the patient and/or family.',
          },
          subject: {
            reference: "Patient/210034",
          },
          encounter: {
            reference: "Encounter/71",
          },
          performedPeriod: {
            start: "2022-12-02T00:00:00-05:00",
            end: "2022-12-02T00:00:00-05:00",
          },
          location: {
            reference: "Location/12",
            display: "ELEVATED HEALTH",
          },
        },
        search: {
          mode: "match",
        },
      },
    ],
  },
  Provenance: {
    resourceType: "Provenance",
    id: "210034",
    meta: {
      versionId: "1",
      lastUpdated: "2022-12-19T06:48:09.0119673-05:00",
      source: "#iLFSV7OLv0KF8dmQ",
      profile: [
        "http://hl7.org/fhir/us/core/StructureDefinition/us-core-provenance",
      ],
    },
    target: [
      {
        reference: "Procedure/10",
        display: "Procedure",
      },
      {
        reference: "Goal/2",
        display: "Goal",
      },
      {
        reference: "Encounter/71",
        display: "Encounter",
      },
      {
        reference: "Medication/7-a",
        display: "Medication",
      },
      {
        reference: "DocumentReference/75",
        display: "DocumentReference",
      },
      {
        reference: "CareTeam/6",
        display: "CareTeam",
      },
      {
        reference: "CarePlan/1",
        display: "CarePlan",
      },
      {
        reference: "Condition/21",
        display: "Condition",
      },
      {
        reference: "Procedure/10",
        display: "Procedure",
      },
      {
        reference: "Device/1",
        display: "Device",
      },
      {
        reference: "Immunization/8",
        display: "Immunization",
      },
      {
        reference: "AllergyIntolerance/9",
        display: "AllergyIntolerance",
      },
      {
        reference: "Practitioner/4",
        display: "Practitioner",
      },
      {
        reference: "PractitionerRole/PCP",
        display: "PractitionerRole",
      },
      {
        reference: "Patient/210034",
        display: "Patient",
      },
      {
        reference: "Location/12",
        display: "Location",
      },
      {
        reference: "Organization/2",
        display: "Organization",
      },
      {
        reference: "Observation/24",
        display: "Observation",
      },
      {
        reference: "Observation/25",
        display: "Observation",
      },

      {
        reference: "Observation/18-v",
        display: "Observation",
      },
      {
        reference: "Observation/2-r",
        display: "Observation",
      },
    ],
    recorded: "2022-12-19T06:48:09.0120453-05:00",
    agent: [
      {
        type: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/provenance-participant-type",
              code: "author",
              display: "Author",
            },
          ],
          text: "Author",
        },
        who: {
          reference: "Practitioner/4",
          display: "Mr MATTHEW ABINANTE",
        },
        onBehalfOf: {
          reference: "Organization/2",
          display: "ELEVATED HEALTH INC",
        },
      },
      {
        type: {
          coding: [
            {
              system:
                "http://hl7.org/fhir/us/core/CodeSystem/us-core-provenance-participant-type",
              code: "transmitter",
              display: "Transmitter",
            },
          ],
          text: "Transmitter",
        },
        who: {
          reference: "Practitioner/4",
          display: "Mr MATTHEW ABINANTE",
        },
        onBehalfOf: {
          reference: "Organization/2",
          display: "ELEVATED HEALTH INC",
        },
      },
    ],
  },
  "Pulse Oximetry": {
    resourceType: "Bundle",
    id: "bdd64d88-a820-4366-a4c8-ec8073d6d712",
    meta: {
      lastUpdated: "2022-12-19T06:47:47.5603927-05:00",
    },
    type: "searchset",
    total: 5,
    link: [
      {
        relation: "self",
        url: "https://fhirapi.medifusion.com/Observation/_search",
      },
    ],
    entry: [
      {
        fullUrl: "https://fhirapi.medifusion.com/Observation/21",
        resource: {
          resourceType: "Observation",
          id: "21",
          meta: {
            versionId: "1",
            lastUpdated: "2022-12-19T06:47:47.5604058-05:00",
            source: "#iLFSV7OLv0KF8dmQ",
            profile: [
              "http://hl7.org/fhir/StructureDefinition/us-core-pulse-oximetry",
            ],
          },
          status: "final",
          category: [
            {
              coding: [
                {
                  system:
                    "http://terminology.hl7.org/CodeSystem/observation-category",
                  code: "vital-signs",
                  display: "vital-signs",
                },
              ],
            },
          ],
          code: {
            coding: [
              {
                system: "http://loinc.org",
                code: "59408-5",
                display:
                  "Oxygen saturation in Arterial blood by Pulse oximetry",
              },
              {
                system: "http://loinc.org",
                code: "2708-6",
                display: "Oxygen saturation in Arterial blood",
              },
            ],
            text: "Oxygen saturation in Arterial blood by Pulse oximetry",
          },
          subject: {
            reference: "Patient/210034",
          },
          encounter: {
            reference: "Encounter/71",
          },
          effectiveDateTime: "2022-12-02T05:25:00-05:00",
          issued: "2022-12-02T05:25:00-05:00",
          valueQuantity: {
            value: 35,
            unit: "%",
            system: "http://unitsofmeasure.org",
            code: "%",
          },
          component: [
            {
              code: {
                coding: [
                  {
                    system: "http://loinc.org",
                    code: "3150-0",
                    display: "Inhaled oxygen concentration",
                  },
                ],
                text: "Inhaled oxygen concentration",
              },
              valueQuantity: {
                value: 95,
                unit: "%",
                system: "http://unitsofmeasure.org",
                code: "%",
              },
            },
            {
              code: {
                coding: [
                  {
                    system: "http://loinc.org",
                    code: "3151-8",
                    display: "Respiratory rate",
                  },
                ],
                text: "Respiratory rate",
              },
              valueQuantity: {
                value: 98,
                unit: "L/min",
                system: "http://unitsofmeasure.org",
                code: "L/min",
              },
            },
          ],
        },
        search: {
          mode: "match",
        },
      },
    ],
  },
  RespirationRate: {
    resourceType: "Bundle",
    id: "20504cc1-3a6c-4576-9ec6-e69e9774b8c0",
    meta: {
      lastUpdated: "2022-12-19T06:48:04.7667707-05:00",
    },
    type: "searchset",
    total: 5,
    link: [
      {
        relation: "self",
        url: "https://fhirapi.medifusion.com/Observation/_search",
      },
    ],
    entry: [
      {
        fullUrl: "https://fhirapi.medifusion.com/Observation/21",
        resource: {
          resourceType: "Observation",
          id: "21",
          meta: {
            versionId: "1",
            lastUpdated: "2022-12-19T06:48:04.7667835-05:00",
            source: "#iLFSV7OLv0KF8dmQ",
            profile: [
              "http://hl7.org/fhir/StructureDefinition/resprate",
              "http://hl7.org/fhir/StructureDefinition/vitalsigns",
            ],
          },
          status: "final",
          category: [
            {
              coding: [
                {
                  system:
                    "http://terminology.hl7.org/CodeSystem/observation-category",
                  code: "vital-signs",
                  display: "vital-signs",
                },
              ],
            },
          ],
          code: {
            coding: [
              {
                system: "http://loinc.org",
                code: "9279-1",
                display: "Respiratory rate",
              },
            ],
            text: "Respiratory rate",
          },
          subject: {
            reference: "Patient/210034",
          },
          encounter: {
            reference: "Encounter/71",
          },
          effectiveDateTime: "2022-12-02T05:25:00-05:00",
          issued: "2022-12-02T05:25:00-05:00",
          valueQuantity: {
            value: 98,
            unit: "/min",
            system: "http://unitsofmeasure.org",
            code: "/min",
          },
        },
        search: {
          mode: "match",
        },
      },
    ],
  },
  "Smoking Status": {
    resourceType: "Bundle",
    id: "3a88c7a0-5620-46a2-8b95-a74cf6141957",
    meta: {
      lastUpdated: "2022-12-19T06:47:41.3955459-05:00",
    },
    type: "searchset",
    total: 5,
    link: [
      {
        relation: "self",
        url: "https://fhirapi.medifusion.com/Observation/_search",
      },
    ],
    entry: [
      {
        fullUrl: "https://fhirapi.medifusion.com/Observation/14-v",
        resource: {
          resourceType: "Observation",
          id: "14-v",
          meta: {
            versionId: "1",
            lastUpdated: "2022-12-19T06:47:41.3955552-05:00",
            source: "#iLFSV7OLv0KF8dmQ",
            profile: [
              "http://hl7.org/fhir/StructureDefinition/us-core-smokingstatus",
            ],
          },
          status: "final",
          code: {
            coding: [
              {
                system: "http://loinc.org",
                code: "72166-2",
                display: "Tobacco smoking status NHIS",
              },
            ],
            text: "Tobacco smoking status NHIS",
          },
          subject: {
            reference: "Patient/210034",
          },
          encounter: {
            reference: "Encounter/71",
          },
          effectiveDateTime: "2022-12-02T00:00:00-05:00",
          issued: "2022-12-02T00:00:00-05:00",
          valueCodeableConcept: {
            coding: [
              {
                system: "http://snomed.info/sct",
                code: "449868002",
                display: "Current every day smoker",
              },
            ],
            text: "Current every day smoker",
          },
        },
        search: {
          mode: "match",
        },
      },
    ],
  },
};

export default ApiData;
