import React, { useEffect, useState } from "react";
import Code from "../Shared/CodeHighlight";
function ApiInfo({ setReadMore, data, noReadMore }) {
  return (
    <>
      <div
        class="width50AtFull02 darkBg darkBgTxt  ml-4 pt-4"
        style={{ marginTop: "2.4rem" }}
      >
        <div style={{ maxHeight: "100%", overflow: "hidden" }}>
          <Code code={JSON.stringify(data)} language="json" />
        </div>
        {/* <Code code={htmlCode} language="html" /> */}
        {/* <Code code={htmlCode} language="html" /> */}

        {!noReadMore && <button
          class="readmre"
          onClick={() => setReadMore(JSON.stringify(data))}
        >
          Read More...
        </button>}
      </div>
      {/* <div
        className="darkBg darkBgTxt position-fixed "
        style={{ width: "100vw", height: "100vh", zIndex: 10 }}
      ></div> */}
    </>
  );
}

export default ApiInfo;
