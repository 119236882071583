import React, { useEffect, useRef, useState } from "react";
import ApiDetailScreen from "./ApiDetailScreen";
import ApiInfo from "./ApiInfo";

const getAllParams = (apiLink) => {
  let finalQuery = "";
  if (apiLink.apiDetail?.hasOwnProperty("params")) {
    Object.keys(apiLink.apiDetail.params).forEach(
      (key, index) =>
      (finalQuery +=
        key +
        "=" +
        apiLink.apiDetail.params[key] +
        (index === Object.keys(apiLink.apiDetail.params).length - 1
          ? ""
          : "&"))
    );
  }
  return finalQuery;
};
const options = {
  rootMargin: "0px",
  threshold: .2
}
const ApiLink = ({ apiLink, subLink, setReadMore, showHeader }) => {
  const apiRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)
  useEffect(() => {
    let apiLinkObserve;
    if (apiRef.current) {
      apiLinkObserve = new IntersectionObserver((entries) => {
        let interacting = entries[0]
        if (interacting.isIntersecting) {
          setIsVisible(true)
        } else {
          setIsVisible(false)
        }

      }, { ...options, root: null })
      apiLinkObserve.observe(apiRef.current)
    }

    return () => {
      apiLinkObserve.unobserve(apiRef.current)
    }
  }, [])
  return (
    <div
      ref={apiRef}
      className="singleContent d-flex justify-content-between "
      style={{ width: "203%", minHeight: "450px" }}
      id={`${apiLink.label.replaceAll(" ", "_") +
        "_" +
        apiLink.method +
        "_" +
        subLink.label.replaceAll(" ", "_")
        }`}
    >
      <div className="width50AtFull">
        <div className="txtUnderline01" >
          <span>
            <span
              style={{
                color: methodColor(apiLink.method),
              }}
              className="mr-3"
            >
              {apiLink.method}
            </span>
            {apiLink.label}
          </span>
          {/* checking for authorization */}
        </div>
        {isVisible && <div className="linkBar my-2">
          <a href style={{ fontSize: ".8rem" }}>
            {apiLink.resource.indexOf("?") !== -1
              ? process.env.REACT_APP_URL +
              apiLink.resource.slice(0, apiLink.resource.indexOf("?")) +
              "?" +
              getAllParams(apiLink)
              : process.env.REACT_APP_URL + apiLink.resource}
          </a>
        </div>}
        {/* {showHeader && <AuthorizationHeader />} */}
        {/*  */}
        {(apiLink.apiDetail?.hasOwnProperty("Authorization") && isVisible) && (
          <>
            <span className="font-weight-bold my-3 d-block text-uppercase">
              Authorization{" "}
              <span
                style={{ fontSize: ".9rem" }}
                className="font-weight-light ml-2"
              >
                {apiLink.apiDetail.Authorization.type}
              </span>
              <span
                className="d-block w-100 mt-0"
                style={{
                  opacity: 0.3,
                  background: "gray",
                  height: ".1px",
                }}
              ></span>
            </span>
            <span style={{ fontSize: ".8rem" }} className="font-weight-bold">
              <span>Token</span>
              <span className="font-weight-normal ml-4">
                {apiLink.apiDetail.Authorization.token}
              </span>
            </span>
          </>
        )}
        {/*  */}
        {(apiLink.apiDetail?.hasOwnProperty("params") && isVisible) && (
          <>
            <span className="font-weight-bold my-3 d-block text-uppercase">
              {apiLink.apiDetail.label}
              <span
                className="d-block w-100 mt-0"
                style={{
                  opacity: 0.3,
                  background: "gray",
                  height: ".1px",
                }}
              ></span>
            </span>
            {Object.keys(apiLink.apiDetail.params).map((key) => {
              return (
                <div
                  className="py-2"
                  style={{
                    fontSize: ".8rem",
                    width: "100%",
                  }}

                // style={{
                //   // borderTop: "1px solid gray",
                //   // borderBottom: "1px solid gray",
                // }}
                >
                  <span
                    style={{
                      minWidth: "130px",
                      display: "inline-block",
                      fontWeight: "700",
                    }}
                  >
                    {key}{" "}
                  </span>
                  <span className="ml-4">{apiLink.apiDetail.params[key]}</span>
                </div>
              );
            })}
          </>
        )}
      </div>
      {isVisible && <ApiInfo setReadMore={setReadMore} data={apiLink.body} />}
    </div>
  );
};
const AuthorizationHeader = () => {
  return (
    <div className="my-3">
      <span>
        <span className="font-weight-bold mr-3">OAuth 2.0</span>
        <span className="">Authorization</span>
      </span>
      <span
        style={{
          height: "1px",
          width: "100%",
          display: "block",
          background: "black",
          margin: "10px 0px",
          opacity: 0.3,
        }}
      ></span>
      <span className="font-weight-light" style={{ fontSize: ".8rem" }}>
        this folder is using OAuth 2.0 from collection{" "}
        <span style={{ cursor: "pointer", color: "orange", fontWeight: 600 }}>
          Medifusion
        </span>
      </span>
    </div>
  );
};
const methodColors = {
  GET: "green",
  POST: "orange",
};
const methodColor = (method) => {
  return methodColors[method];
};
function MainInfo({ leftMenu }) {
  const [readMore, setReadMore] = useState(false);
  return (
    <>

      <div
        style={{
          // maxHeight: `${readMore ? "91vh" : "none"}`,
          overflow: `${readMore ? "hidden" : "auto"}`,
        }}
        className={`content-container  colSingle pt-5 `}
      >
        <div
          className="singleContent"
        // id={`${mainLink.label.replaceAll(" ", "_")}`}
        >
          <div className="width50AtFull">
            <div className="width50padd">
              <h1 className="mb-0">Medifusion FHIR Documentation</h1>
              <span className="mt-3 d-block">Technical documentation regarding FHIR resources and API methods to interoperate with Medifusion FHIR platform will be found here.
                Default syntax is JSON, authorization is performed by using OAuth2 (under TLS 1.2)
              </span>


            </div>


          </div>

        </div>
        {leftMenu.map((mainLink) => {
          return (
            <div
              className="singleContent"
              id={`${mainLink.label.replaceAll(" ", "_")}`}
            >
              <div className="width50AtFull">
                <div className="width50padd">
                  <h2>{mainLink.label}</h2>
                  {mainLink.data && mainLink.data}
                  {mainLink.hasOwnProperty("noAuthorization") && (
                    <AuthorizationHeader />
                  )}
                  {mainLink.links &&
                    mainLink.links.map((subLink) => {
                      return (
                        <>
                          {subLink.type === "heading" ? (
                            <>
                              <div
                                className="txtUnderline01"
                                id={`${subLink.label.replaceAll(" ", "_")}`}
                              >
                                {subLink.label}
                              </div>
                              <p>{subLink.description}</p>
                              {subLink?.apiDetail?.Authorization && (
                                <AuthorizationHeader />
                              )}
                              {subLink.links &&
                                subLink.links.map((apiLink) => {
                                  return (
                                    <>
                                      <ApiLink
                                        apiLink={apiLink}
                                        subLink={subLink}
                                        setReadMore={setReadMore}
                                        showHeader={false}
                                      />
                                    </>
                                  );
                                })}{" "}
                            </>
                          ) : subLink.type === "API" ? (
                            <>
                              <ApiLink
                                apiLink={subLink}
                                subLink={mainLink}
                                setReadMore={setReadMore}
                                showHeader={true}
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {readMore && <ApiDetailScreen data={readMore} close={setReadMore} />}
    </>
  );
}

export default MainInfo;
