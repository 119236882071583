import React from "react";

function NavigateLink({ children, link }) {
  return (
    <a
      className="mx-1"
      href={link}
      target="_blank"
      style={{
        textDecoration: "underline",
        color: "darkorange",

        cursor: "pointer",
      }}
    >
      {children}
    </a>
  );
}

export default NavigateLink;
