import React from "react";
let TDataStyle = {};
let THeadStyle = { border: "1px solid #d3d3d378" };
function ErrorsExceptions() {
  return (
    <>
      <h4 className="font-weight-bold my-2">ERROR HANDLING</h4>{" "}
      <p>
        Requests made without an access token, an invalid or expired access
        token, or for resources not permitted under the user's privileges
        defined by the authorization will result in an HTTPS 401 status code. An
        "Unauthorized" API may return a status code for communicating the result
        of each transaction/API call. The complete list of status codes
        typically returned by API are as follows:
      </p>
      <table>
        <thead>
          <tr>
            <th className="p-2" style={THeadStyle}>
              Code
            </th>
            <th className="p-2" style={THeadStyle}>
              Meaning
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="p-2" style={THeadStyle}>
              200
            </td>
            <td className="p-2" style={THeadStyle}>
              The request was processed appropriately.
            </td>
          </tr>
          <tr>
            <td className="p-2" style={THeadStyle}>
              400
            </td>
            <td className="p-2" style={THeadStyle}>
              Invalid parameter.
            </td>
          </tr>
          <tr>
            <td className="p-2" style={THeadStyle}>
              401
            </td>
            <td className="p-2" style={THeadStyle}>
              The request did not have a valid authorization token or none was
              provided. Obtain a valid authorization token and add it to the
              authorization header.
            </td>
          </tr>
          <tr>
            <td className="p-2" style={THeadStyle}>
              403
            </td>
            <td className="p-2" style={THeadStyle}>
              The user is not authorized for the operation. The user may not
              have access to the patient, or an invalid scope was provided.
            </td>
          </tr>
          <tr>
            <td className="p-2" style={THeadStyle}>
              404
            </td>
            <td className="p-2" style={THeadStyle}>
              Not found.
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default ErrorsExceptions;
