import React from "react";
const methodColors = {
  GET: "green",
  POST: "orange",
};
const methodColor = (method) => {
  return methodColors[method];
};
let APILink = ({ sublink, link }) => {
  return (
    <li
      style={{ padding: "9px" }}
      onClick={(e) => {
        e.stopPropagation();
        const id = `${
          sublink.label.replaceAll(" ", "_") +
          "_" +
          sublink.method +
          "_" +
          link.label.replaceAll(" ", "_")
        }`;
        const yOffset = -80;
        const element = document.getElementById(id);
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }}
    >
      <a
        data-toggle="collapse"
        href={`#dropMenu${sublink.label.replaceAll(" ", "_")}`}
        //   className="active"
      >
        <span
          style={{
            marginRight: "5px",
            color: methodColor(sublink.method),
          }}
        >
          {sublink.method}
        </span>

        {sublink.label}
      </a>
    </li>
  );
};
function LeftMenu({ leftMenu }) {
  return (
    <div className="slideout-sidebar">
      <ul>
        {/* <li>
          <span className="darkTxt">Bellmedex </span>
          <span className="suppTxt02">FHIR</span>
        </li> */}
        {/* active  */}
        {leftMenu.map((leftMenuItem) => {
          return (
            <li>
              <a
                href={`#dropMenu${leftMenuItem.label.replaceAll(" ", "_")}`}
                onClick={() => {
                  const id = `${leftMenuItem.label.replaceAll(" ", "_")}`;
                  const yOffset = -80;
                  const element = document.getElementById(id);
                  const y =
                    element.getBoundingClientRect().top +
                    window.pageYOffset +
                    yOffset;
                  window.scrollTo({ top: y, behavior: "smooth" });
                }}
                data-toggle="collapse"
                className=""
              >
                {leftMenuItem.label}
              </a>
              {leftMenuItem.links?.length > 0 && (
                <ul
                  id={`dropMenu${leftMenuItem.label.replaceAll(" ", "_")}`}
                  className="collapse"
                >
                  {leftMenuItem.links.map((link) => {
                    return link.type === "heading" ? (
                      <>
                        <li
                          onClick={(e) => {
                            const id = `${link.label.replaceAll(" ", "_")}`;
                            const yOffset = -80;
                            const element = document.getElementById(id);
                            const y =
                              element.getBoundingClientRect().top +
                              window.pageYOffset +
                              yOffset;
                            window.scrollTo({ top: y, behavior: "smooth" });

                            // document
                            //   .querySelector(
                            //     `#${link.label.replaceAll(" ", "_")}`
                            //   )
                            //   .scrollIntoView({
                            //     behavior: "smooth",
                            //     block: "start",
                            //   });
                          }}
                        >
                          <a
                            href={`#dropMenu${link.label.replaceAll(" ", "_")}`}
                            data-toggle="collapse"
                            // className="active"
                          >
                            {link.label}
                          </a>

                          {link.links?.length > 0 && (
                            <ul
                              id={`dropMenu${link.label.replaceAll(" ", "_")}`}
                              className="collapse"
                            >
                              {link.links.map((sublink) => {
                                return (
                                  <APILink sublink={sublink} link={link} />
                                );
                              })}
                            </ul>
                          )}
                        </li>
                      </>
                    ) : link.type === "API" ? (
                      <APILink sublink={link} link={leftMenuItem} />
                    ) : (
                      ""
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default LeftMenu;
