import React from "react";
import Code from "../Shared/CodeHighlight";
import ApiInfo from "./ApiInfo";



let tokenResponse = {
  "id_token": "612C65955C74AD290110E5675184386CEE07C6EFF4C26E052DF2A6F9089C4189",
  "access_token": "612C65955C74AD290110E5675184386CEE07C6EFF4C26E052DF2A6F9089C4189",
  "expires_in": 3600,
  "token_type": "Bearer",
  "refresh_token": "612C65955C74AD290110E5675184386CEE07C6EFF4C26E052DF2A6F9089C4189",
  "scope": "launch/patient openid fhirUser patient/Medication.read patient/AllergyIntolerance.read patient/CarePlan.read patient/CareTeam.read patient/Condition.read patient/Device.read patient/DiagnosticReport.read patient/DocumentReference.read patient/Encounter.read patient/Goal.read patient/Immunization.read patient/Location.read patient/MedicationRequest.read patient/Observation.read patient/Organization.read patient/Patient.read patient/Practitioner.read patient/Procedure.read patient/Provenance.read patient/PractitionerRole.read offline_access",
  "patient": "210034",
  "need_patient_banner": "false",
  "smart_style_url": "https://fhirapi.medifusion.com/app/smart-style-url"
}



let requestExample = `POST /connect/token HTTPS/1.1
Host: https:/xxxxxxxxxxxxxx
Content-Length: 29
Content-Type: application/x-www-form-urlencoded`;
let authResponseBody = `{
    "expires_in":"3600",
    "token_type":"Bearer",
    "scope":"patient/.read",
    "access_token":"eyJhbGciOiJSUzI1NiIsImtpZCI6IjI",
    "refresh_token": "FE5CEEC134EFE464FA1219DF893BD4"
}
`;
let clientCredResponseBody = `{
  "grant_type": "client_credentials",
  "client_id": "client@de1e0d02ab48f9a28639b61a28b2e5",
  "client_secret": "ML-VIla8R2mgZftdEHyYzw==",
  "scope": "patient/.read"
}`;
let apiURL = `https://apiauth.medifusion.com/connect/token`;
let authenticateApi = `{
    "expires_in":3600,
    "token_type":"Bearer",
    "scope":"patient/.read",
    "access_token":"eyJhbGciOiJSUzI1NiIsImtpZCI6IjI",
    "refresh_token": "FE5CEEC134EFE464FA1219DF893BD4"
}`;
let tokenValidationData = `
{
    "nbf": 1664531802,
    "exp": 1664535402,
    "iss": "https://xxxx.com",
    "aud": [
        "system.fhir.access.api",
        "patient.fhir.access.api",
        "user.fhir.access.api",
        "postman.api"
    ],
    "client_id": "XXXClient",
    "client_User": "XXX",
    "sub": "a7a9e448-be81-4627-8bec-24bd7adb9f20",
    "auth_time": 1664531793,
    "idp": "local",
       "jti": "B6314E46CD4AB1F3A1282B3E32CA81DF",
    "sid": "D6D18A2B841729ED9B0F40653E04ABB7",
    "iat": 1664531802,
    "amr": "pwd",
    "active": true,
    "scope": "patient/Provenance.read offline_access"
}`;


const AuthorizationFlow = () => {

  return <>

    <div className="singleContent" style={{ width: "203%" }}>
      <div className="width50AtFull">

        <h5 className="mt-2 font-weight-bold">Authorization Flow</h5>
        <span className="my-2 d-block">Medifusion Used OAuth 2.0 Authorization Code flow.</span>
        <ol>
          <li className="mt-2">Request the authorization code from the Medifusion Authorization Server.</li>
          <li className="mt-2">Once the Authorization code is retrieved from the Medifusion Authorization Server, a request for Access token
            and a Refresh token must made.</li>
          <li className="mt-2">If the request is successful, an Access token and Refresh Token will be Provided.
            All API requests must include an Authorization header with an Access Token of the form : <span className="d-flex">
              Authorization : <span className="mt-1 ml-2 d-block"><Code code="Bearer MY_ACCESS_TOKEN" /></span></span>

            <span>After the expiration a new Access token must be created by submitting the Refresh token created in <b>step 2</b></span>
          </li>
        </ol>

      </div>


      <ApiInfo data={tokenResponse} noReadMore />

    </div>

  </>
}
const ApiInformation = () => {

  return <>
    <h5 className="mt-2 font-weight-bold">API Information</h5>
    <ul>
      <li> FHIR Base URL:

        <Code code="https://fhirapi.medifusion.com/api" />
      </li>
      <li> FHIR Capability Statement URL:

        <Code code="https://fhirapi.medifusion.com/api/metadata" />
      </li>
      <li>   SMART Configuration URL:

        <Code code="https://fhirapi.medifusion.com/api/.well-known/smart-configuration" />

      </li>
    </ul>


  </>
}

const TokenRefresh = () => {
  return <>

    <h5 className="mt-2 font-weight-bold">Token Refresh / OFFLINE_ACCESS</h5>

    <span>A Refresh Token is returned by the authorize endpoint when the offline_access scope is requested and obtained with the user’s authorization.
      To get an access token from a refresh token, you will need to form a new token request providing the refresh token in the refresh_token parameter a
      nd refresh_token as the value for the grant_type parameter.
      An app will also need to specify in the scope parameter which of the previously granted scopes your app would like included in the new access token.</span>
  </>
}


const AccessingResources = () => {
  return <>
    <h5 className="mt-2 font-weight-bold">Accessing Resources</h5>
    <span>To access FHIR® resources utilizing an access token, include a "bearer" authorization header in your HTTP request per RFC 6750 as follows:</span>
    <span className="d-block  mt-2"> <Code code="Authorization: Bearer eyJraWQiOiIyMDE2LTExLTAxVDE1OjExOjQ4LjY1NS5lYyIsInR5" /></span>
  </>
}
const ComponentsConfig = () => {
  return <>
    <h5 className="mt-2 font-weight-bold">Components and Configurations</h5>
    <ul>
      <li><span className="font-weight-bold">Confidential Clients:</span> To be registered as a confidential client, the client must be capable of
        storing a client secret.</li>
      <li><span className="font-weight-bold">Public Clients:</span> Clients that are not capable of securely storing a client secret will be
        registered as public clients.</li>
      <li><span className="font-weight-bold">Bulk Export:</span> Clients using the Bulk Export API must support authorization using the Client
        Credentials Flow with JWT Credential.</li>
      <li><span className="font-weight-bold">Standalone Launch:</span> Standalone Launch is currently supported for patient-facing 3rd-party
        applications. These Apps must support the Authorization Code Flow as outlined in the
        SMART App Launch IG 1.0.0.</li>
      <li><span className="font-weight-bold"> EHR Launch:</span> EHR Launch is currently supported for provider-facing applications that are
        integrated with the EHR. These Apps must support the Authorization Code Flow as outlined
        in the SMART App Launch IG 1.0.0.</li>
    </ul></>
}
const AuthorizationInfo = () => {
  return <>
    <h5 className="mt-2 font-weight-bold">Authorization Server Info</h5>
    <ul>
      <li><span className="">The FHIR Authorization root URL :</span><Code code="https://apiauth.medifusion.com/connect/authorize" /></li>
      <li><span className="">Token URL : </span> <Code code="https://apiauth.medifusion.com/connect/token" /></li>
      <li><span className="">OpenID Connect well-known configuration	:</span> <Code code="https://apiauth.medifusion.com/.well-known/openid-configuration" /></li>

    </ul></>
}
function ClientRegistration() {
  // const [readMore, setReadMore] = React.useState(false)

  return (
    <>

      <span>
        Client Applications can be registered with Medifusion by submitting a request by filling out the
        <a
          target="_blank"
          href="https://medifusion.com/fhir-registration/"
          className="nav-link mr-2 p-0 d-inline-block"
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            color: "darkorange",
          }}
        >
          Client Registration Form.
        </a>
        Once the registration process is successfully completed, Client will be issued an OAuth Client ID, Secret Key.
      </span>

      <ComponentsConfig />
      <AuthorizationInfo />
      <ApiInformation />
      <AuthorizationFlow />
      <TokenRefresh />
      <AccessingResources />
      {/* <span className="d-block font-weight-bold">1. Retrieve Server MetaData</span>
      <ul>
        <li>The app discovers the EHRs FHIR server metadata, including OAuth
          authorization_endpoint and token_endpoint URLs.</li>
      </ul>
      <span className="d-block font-weight-bold">2. Obtain authorization code</span>
      <ul>
        <li>The app sends a request to the authorization endpoint.</li>

      </ul> */}

      {/* <h6 className="mt-2 ">Parameters</h6>
      <ol>
        <li>User login name (required)</li>
        <li>User password (required)</li>
      </ol>
      <span className="font-weight-bold">Request Example</span>
      <span className="my-2 d-block">
        <Code code={requestExample} />
      </span>
      <span className="font-weight-bold">Authorization Response Body</span>
      <span className="my-2 d-block">
        <Code code={authResponseBody} />
      </span>
      <span className="font-weight-bold">Client Credential Response Body</span>
      <span className="my-2 d-block">
        <Code code={clientCredResponseBody} />
      </span>
      <span>
        The API platform's Authorization server will supports OAuth 2.0 grant
        type
      </span> */}




    </>
  );
}

export default ClientRegistration;
