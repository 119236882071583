import React from "react";
const ListItem = ({ title, text }) => (
  <li className="my-2">
    <span className="font-weight-bold">{title}: </span>
    <span className="ml-1">{text}</span>
  </li>
);
function TermsAndCondtions() {
  return (
    <div>
      <h5 className="mt-3">Terms and Conditions of Use</h5>
      <span>
        Please read these Terms of Use before using the API hosted on
        https://fhirapi.medifusion.com/. Your access to use the Service is based
        on your acceptance with these Terms. These Terms apply to all users of
        Medifusion API. If you disagree with any part of the terms then you may
        not access the Service.
      </span>

      <ul className="mt-3">
        <ListItem
          title="Account Security"
          text={`You are responsible for safeguarding the password that you use to access
      the Medifusion API and for any activities or actions under your password,
      whether your password is with our Service or a third-party service. You
      agree not to disclose your password to any third party. You must notify us
      immediately upon becoming aware of any breach of security or unauthorized
      use of your account.`}
        />
        <ListItem
          title="Termination of Access and use"
          text={`Company may, in its sole discretion, terminate the right to access and
          use the API of any Eligible Application that has violated these Terms and Conditions`}
        />
        <ListItem
          title="License"
          text={`Company hereby grants you a non-transferable, non-exclusive, limited license to use the API
          subject to the terms and conditions of this Agreement. You may not, either on your own behalf or through
          any agent or third party decompile, disassemble, reverse engineer, or otherwise attempt to derive source
          code from the software components of the API, or modify or create derivative works based on the
          software components of the API.`}
        />
        <ListItem
          title="Modifications"
          text={`Company reserves the right, at our sole discretion, to modify or replace these Terms at
          any time. If a revision is made we will try to provide notice prior to any new terms taking effect. What
          constitutes a material change will be determined at our sole discretion. By continuing to access or use our
          Service after those revisions become effective, you agree to be bound by the revised terms. If you do not
          agree to the new terms, please stop using the Service.`}
        />
        <ListItem
          title="Acceptance"
          text={`Use of the API signifies your acknowledgement and acceptance of these Terms of Use. If
          you do not agree to these terms of use, please do not use the API.`}
        />
      </ul>
    </div>
  );
}

export default TermsAndCondtions;
