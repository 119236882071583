import React, { useEffect } from "react";
import Code from "../Shared/CodeHighlight";

function ApiDetailScreen({ data, close }) {
  let handleEsc = (e) => {
    if (e.keyCode === 27) {
      close(null);
    }
  };
  // const handleOnScroll = (e) => {
  //   e.preventDefault();
  //   var x = window.scrollX;
  //   var y = window.scrollY;
  //   console.log(x, y);
  //   window.scrollTo(x, y);
  // };
  useEffect(() => {
    // window.addEventListener("scroll", handleOnScroll);
    document.addEventListener("keydown", handleEsc);
    return () => {
      // window.removeEventListener("scroll", handleOnScroll);
      document.removeEventListener("keydown", handleEsc);
    };
  }, []);
  return (
    <div
      className="position-fixed"
      style={{
        height: "100vh",
        background: "black",
        zIndex: 99,
        width: "100vw",
        top: 0,
        opacity: 0.98,
        overflowX: "hidden",
      }}
    >
      <div className="h-100">
        <div className="d-flex justify-content-between py-2">
          <span className="mx-4" style={{ color: "white" }}>
            Press ESC to <span className="font-weight-bold">Close</span>
          </span>
          <i
            onClick={() => close(null)}
            style={{ color: "white", fontSize: "1.45rem", cursor: "pointer" }}
            class="fa-solid fa-xmark mx-4"
          ></i>
        </div>
        <Code code={data} language="json" />
      </div>
    </div>
  );
}

export default ApiDetailScreen;
