import LeftMenu from "./Components/LeftMenu";
import MainInfo from "./Components/MainInfo";
import NavBar from "./Components/NavBar";
import "./style/css/style.css";
import "jquery/dist/jquery.slim.js";
import "jquery/dist/jquery.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import React from "react";
import PatientApis from "./PatientApis";
import ClientRegistration from "./Components/ClientRegistration";
import FHIRBaseURL from "./Components/FHIRBaseURL.js";
import NavigateLink from "./Shared/NavigateLink";
import ErrorsExceptions from "./Components/ErrorsExceptions";
import ApiData from "./Shared/ApiData";
import TermsAndCondtions from "./Components/TermsAndCondtions";
const patientID = 210034;

const handleDocRef = () => {
  const id = `DocumentReference`;
  const yOffset = -80;
  const element = document.getElementById(id);
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({ top: y, behavior: "smooth" });
};

const Info = [
  // {
  //   label: "Medifusion FHIR Documentation",
  //   data: <FHIRBaseURL />,
  //   links: [
  //     // {
  //     //   type: "heading",
  //     //   label: "Errors",
  //     //   description:
  //     //     "This resource conforms to USCDI profile for Patient Demographics - refer to USCore Patient Profile. Patient resource response can be requested in JSON or XML format as per FHIR standard R4 version Patient - FHIR v4.0.1 (hl7.org) supports the following",
  //     // },
  //   ],
  // },
  // {
  //   label: "SMART On FHIR Discovery",
  //   links: [
  //     {
  //       type: "API",
  //       method: "GET",
  //       resource: "api/.well-known/smart-configuration",
  //       label: "OpenID Discovery Endpoint",
  //       body: PatientApis.searchPatient,
  //     },
  //     {
  //       type: "API",
  //       method: "GET",
  //       resource: "api/.well-known/smart-configuration",
  //       label: "Discovery of Server Capabilities",
  //       body: PatientApis.searchPatient,
  //     },
  //     {
  //       type: "API",
  //       method: "GET",
  //       label: "Metadata",
  //       resource: "api/metadata",
  //       body: PatientApis.searchPatient,
  //     },
  //   ],
  // },
  {
    label: "Client Registration",
    data: <ClientRegistration />,
    // links: [
    //   // {
    //   //   type: "heading",
    //   //   label: "Errors",
    //   //   description:
    //   //     "This resource conforms to USCDI profile for Patient Demographics - refer to USCore Patient Profile. Patient resource response can be requested in JSON or XML format as per FHIR standard R4 version Patient - FHIR v4.0.1 (hl7.org) supports the following",
    //   // },
    // ],
  },
  {
    label: "Resources",
    type: "heading",
    links: [
      {
        label: "Allergy Intolerance",
        description: (
          <>
            This resource conforms to USCDI profile for Allergy Intolerance -
            refer to{" "}
            <NavigateLink link="https://hl7.org/fhir/us/core/stu3.1.1/StructureDefinition-us-core-allergyintolerance.html">
              US Core AllergyIntolerance Profile.
            </NavigateLink>{" "}
            Allergy Intolerance response can be requested in JSON or XML format
            as per FHIR standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/allergyintolerance.html">
              AllergyIntolerance - FHIR v4.0.1
            </NavigateLink>
            (hl7.org) supports the following data elements
          </>
        ),
        type: "heading",
        links: [
          {
            type: "API",
            method: "GET",
            resource: `api/AllergyIntolerance?patient=${patientID}&_revinclude=Provenance:target`,
            label: "Search Allergies",
            body: ApiData.Allergy,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PARAMS",
              params: {
                patient: patientID,
                _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "GET",
            resource: "api/AllergyIntolerance/:id",
            label: "Allergy Intolerance By Id",
            body: ApiData.Allergy,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "path variables",
              params: {
                id: 3341,
              },
            },
            // apiDetail: {
            //   Authorization: {
            //     type: "Bearer Token",
            //     token: "<token>",
            //   },
            //   // label: "PARAMS",
            //   // params: {
            //   //   patient: patientID,
            //   //   _revinclude: "Provenance:target",
            //   // },
            // },
          },
          {
            type: "API",
            method: "POST",
            label: "_search",
            resource: "api/AllergyIntolerance/_search",
            body: ApiData.Allergy,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "Body",
              params: {
                patient: patientID,
                // _revinclude: "Provenance:target",
              },
            },
          },
        ],
      },
      {
        label: "BMI For Age Observation",
        description: (
          <>
            This resource conforms to USCDI profile for Observation - refer to
            <NavigateLink link="http://hl7.org/fhir/us/core/STU3.1.1/StructureDefinition-pediatric-bmi-for-age.html">
              US Core BMI for Age Observation
            </NavigateLink>{" "}
            Profile. Observation response can be requested in JSON or XML format
            as per FHIR standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/Observation.html">
              Observation - FHIR v4.0.1 (hl7.org)
            </NavigateLink>
            supports the data elements documented as BMI Percentile.
          </>
        ),
        type: "heading",
        links: [
          {
            type: "API",

            method: "GET",
            label: "Search BMI For Age Observation",
            resource: `api/Observation?patient=${patientID}&code=59576-9&_revinclude=Provenance:target&`,
            body: ApiData["Pediatric BMI For Age Observation"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PARAMS",
              params: {
                patient: patientID,
                category: "vital-signs",
                date: `2022-08-31T12:32:00%2B00:00`,
                code: `59576-9`,
                _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "GET",
            resource: `api/Observation/:id`,
            label: "Observation By Id",
            body: ApiData["Pediatric BMI For Age Observation"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "path variables",
              params: {
                id: 14443,
              },
            },
          },
          {
            type: "API",
            method: "POST",
            resource: `api/Observation/_search`,
            label: "_search",
            body: ApiData["Pediatric BMI For Age Observation"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "body",
              params: {
                category: "vital-signs",
                patient: patientID,
                code: "59576-9",
              },
            },
          },
        ],
      },
      {
        label: "Condition Health Concerns",
        description: (
          <>
            This resource conforms to USCDI profile for Condition - refer to{" "}
            <NavigateLink link="http://hl7.org/fhir/us/core/STU3.1.1/StructureDefinition-us-core-condition.html">
              US Core Condition Profile.
            </NavigateLink>{" "}
            Condition response can be requested in JSON or XML format as per
            FHIR standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/Condition.html">
              Condition - FHIR v4.0.1 (hl7.org)
            </NavigateLink>{" "}
            supports the following data elements documented as follows.
          </>
        ),
        type: "heading",
        links: [
          {
            type: "API",
            method: "GET",
            resource: `api/Condition?patient=${patientID}&_revinclude=Provenance:target`,
            label: "Search Condition",
            body: ApiData.Condition,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PARAMS",
              params: {
                patient: patientID,
                _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "GET",
            label: "Condition By Id",
            resource: "api/Condition/:id",
            body: ApiData.Condition,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PATH variables",
              params: {
                id: 22311,
              },
            },
          },
          {
            type: "API",
            method: "POST",
            label: "_search",
            body: ApiData.Condition,
            resource: "api/Condition/_search",
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "BODY",
              params: {
                patient: patientID,
                // _revinclude: "Provenance:target",
              },
            },
          },
        ],
      },
      {
        label: "CarePlan",
        type: "heading",
        description: (
          <>
            This resource conforms to USCDI profile for CarePlan - refer to{" "}
            <NavigateLink link="https://hl7.org/fhir/us/core/stu3.1.1/StructureDefinition-us-core-careplan.html">
              US Core CarePlan Profile.
            </NavigateLink>{" "}
            CarePlan response can be requested in JSON or XML format as per FHIR
            standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/careplan.html">
              CarePlan - FHIR v4.0.1 (hl7.org)
            </NavigateLink>{" "}
            supports the data elements documented as Assessment and Plan of
            Treatment
          </>
        ),
        links: [
          {
            type: "API",
            method: "GET",
            label: "Search CarePlan",
            body: ApiData["Care Plan"],
            resource: `api/CarePlan?patient=${patientID}&_revinclude=Provenance:target&category=assess-plan`,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PARAMS",
              params: {
                patient: patientID,
                _revinclude: "Provenance:target",
                category: "assess-plan",
              },
            },
          },
          {
            type: "API",
            method: "GET",
            label: "CarePlan By Id",
            resource: `api/CarePlan/:id`,
            body: ApiData["Care Plan"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "Path variables",
              params: {
                id: 4451,
                // _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "POST",
            label: "_search",
            resource: `api/CarePlan/_search`,
            body: ApiData["Care Plan"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "BODY",
              params: {
                patient: patientID,
                category: "assess-plan",
              },
            },
          },
        ],
      },
      {
        label: "CareTeam",
        description: (
          <>
            This resource conforms to USCDI profile for CareTeam - refer to
            <NavigateLink link="http://hl7.org/fhir/us/core/STU3.1.1/StructureDefinition-us-core-careteam.html">
              USCore CareTeam Profile.
            </NavigateLink>
            CareTeam response can be requested in JSON or XML format as per FHIR
            standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/careteam.html">
              CareTeam - FHIR v4.0.1 (hl7.org)
            </NavigateLink>{" "}
            supports the following data elements.
          </>
        ),
        type: "heading",
        links: [
          {
            type: "API",
            method: "GET",
            label: "Search CareTeam",
            resource: `api/CareTeam?_revinclude=Provenance:target&patient=${patientID}&status=active`,
            body: ApiData["Care Team"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PARAMS",
              params: {
                _revinclude: "Provenance:target",
                patient: patientID,
                status: "active",
              },
            },
          },
          {
            type: "API",
            method: "GET",
            label: "CareTeam By Id",
            body: ApiData["Care Team"],
            resource: `api/CareTeam/:id`,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "path variables",
              params: {
                id: "2878",
                // _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "POST",
            label: "_search",
            resource: `api/CareTeam/_search`,
            body: ApiData["Care Team"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "BODY",
              params: {
                patient: patientID,
                // _revinclude: "Provenance:target",
              },
            },
          },
        ],
      },
      {
        label: "Device",
        description: (
          <>
            This resource conforms to USCDI profile for Device - refer to{" "}
            <NavigateLink link="http://hl7.org/fhir/us/core/STU3.1.1/StructureDefinition-us-core-implantable-device.html">
              USCore Device Profile.
            </NavigateLink>{" "}
            Device response can be requested in JSON or XML format as per FHIR
            standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/device.html">
              Device - FHIR v4.0.1 (hl7.org)
            </NavigateLink>{" "}
            supports the following data elements documented as Unique Device
            Identifier(s) for Patient's Implantable Device(s).
          </>
        ),
        type: "heading",
        links: [
          {
            type: "API",
            method: "GET",
            resource: `api/Device?patient=${patientID}&_revinclude=Provenance:target`,
            label: "Search Device",
            body: ApiData.Device,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PARAMS",
              params: {
                patient: patientID,
                _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "GET",
            label: "Device By Id",
            resource: `api/Device/:id`,
            body: ApiData.Device,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PATH VARIABLES",
              params: {
                id: "89",
                // _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "POST",
            label: "_search",
            resource: `api/Device/_search`,
            body: ApiData.Device,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "body",
              params: {
                patient: patientID,
                // _revinclude: "Provenance:target",
              },
            },
          },
        ],
      },
      {
        label: "DiagnosticReport",
        description: (
          <>
            This resource conforms to USCDI profile for DiagnosticReport - refer
            to{" "}
            <NavigateLink link="http://hl7.org/fhir/us/core/STU3.1.1/StructureDefinition-us-core-diagnosticreport-lab.html">
              US Core DiagnosticReport Profile.
            </NavigateLink>{" "}
            DiagnosticReport response can be requested in JSON or XML format as
            per FHIR standard R4 version
            <NavigateLink link="http://hl7.org/fhir/R4/diagnosticreport.html">
              DiagnosticReport- FHIR v4.0.1 (hl7.org)
            </NavigateLink>{" "}
            supports the following data elements documented DiagnosticReport as
            Laboratory.
          </>
        ),
        type: "heading",
        links: [
          {
            type: "API",
            resource: `api/DiagnosticReport?patient=${patientID}&code=005009&category=LP29684-5&date=2022-09-19T18%3A50%3A00%2B00%3A00&_revinclude=Provenance:target`,
            method: "GET",
            label: "Search DiagnosticReport",
            body: ApiData["Diag Report"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PARAMS",
              params: {
                patient: patientID,
                code: "005009",
                category: "LP29684-5",
                date: `2022-09-19T18%3A50%3A00%2B00%3A00`,
                _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "GET",
            label: "DiagnosticReport By Id",
            resource: `api/DiagnosticReport/:id`,

            body: ApiData["Diag Report"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "path variables",
              params: {
                id: "664",
                // _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "POST",
            label: "_search",
            body: ApiData["Diag Report"],
            resource: `api/DiagnosticReport/_search`,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "body",
              params: {
                patient: patientID,
                category: "LP29684-5",
              },
            },
          },
        ],
      },
      {
        label: "DocumentReference",
        description: (
          <>
            This resource conforms to USCDI profile for DocumentReference -
            refer to US Core DocumentReference Profile. DocumentReference
            response can be requested in JSON or XML format as per FHIR standard
            R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/documentreference.html">
              DocumentReference - FHIR v4.0.1 (hl7.org)
            </NavigateLink>{" "}
            supports the following data elements:
          </>
        ),
        type: "heading",
        links: [
          {
            type: "API",
            resource: `api/DocumentReference?patient=${patientID}&_revinclude=Provenance:target`,
            method: "GET",
            label: "Search DocumentReference",
            body: ApiData["Document Ref"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PARAMS",
              params: {
                patient: patientID,
                _id: 3389,
                code: "229-A",
                type: "34117-2",
                category: "clinical-note",
                date: `2022-08-24T09:41:00%2B00:00`,
                _revinclude: "Provenance:target",
              },
            },
          },
          // {
          //   type: "API",
          //   method: "GET",
          //   resource: `api/DocumentReference?patient=${patientID}&type=34117-2`,
          //   label: "DocumentReference By Type",
          //   body: ApiData["Document Ref"],
          //   apiDetail: {
          //     Authorization: {
          //       type: "Bearer Token",
          //       token: (
          //         <span>
          //           This request is using Bearer Token from folder{" "}
          //           <a
          //             target="_blank"
          //             // href={`https://fhir-api.ethizo.com/#auth-info-9164262e-b291-4455-9e5c-13a5b18585ed`}
          //             onClick={handleDocRef}
          //             style={{
          //               color: "darkorange",
          //               cursor: "pointer",
          //             }}
          //           >
          //             DocumentReference
          //           </a>
          //         </span>
          //       ),
          //     },
          //     label: "PARAMS",
          //     params: {
          //       patient: patientID,
          //       type: "34117-2",
          //     },
          //   },
          // },
          // {
          //   type: "API",
          //   method: "GET",
          //   label: "DocumentReference By Category",
          //   body: ApiData["Document Ref"],
          //   resource: `api/DocumentReference?patient=${patientID}&category=clinical-note`,
          //   apiDetail: {
          //     Authorization: {
          //       type: "Bearer Token",
          //       token: (
          //         <span>
          //           This request is using Bearer Token from folder{" "}
          //           <a
          //             target="_blank"
          //             // href={`https://fhir-api.ethizo.com/#auth-info-9164262e-b291-4455-9e5c-13a5b18585ed`}
          //             onClick={handleDocRef}
          //             style={{
          //               color: "darkorange",
          //               cursor: "pointer",
          //             }}
          //           >
          //             DocumentReference
          //           </a>
          //         </span>
          //       ),
          //     },
          //     label: "PARAMS",
          //     params: {
          //       patient: patientID,
          //       category: "clinical-note",
          //     },
          //   },
          // },
          // {
          //   type: "API",
          //   method: "GET",
          //   label: "DocumentReference By Date",
          //   body: ApiData["Document Ref"],
          //   resource: `api/DocumentReference?patient=${patientID}&category=clinical-note&date=2022-08-24T09:41:00%2B00:00`,
          //   apiDetail: {
          //     Authorization: {
          //       type: "Bearer Token",
          //       token: (
          //         <span>
          //           This request is using Bearer Token from folder{" "}
          //           <a
          //             target="_blank"
          //             // href={`https://fhir-api.ethizo.com/#auth-info-9164262e-b291-4455-9e5c-13a5b18585ed`}
          //             onClick={handleDocRef}
          //             style={{
          //               color: "darkorange",
          //               cursor: "pointer",
          //             }}
          //           >
          //             DocumentReference
          //           </a>
          //         </span>
          //       ),
          //     },
          //     label: "PARAMS",
          //     params: {
          //       patient: patientID,
          //       category: `clinical-note`,
          //       date: `2022-08-24T09:41:00%2B00:00`,
          //     },
          //   },
          // },
          {
            type: "API",
            method: "GET",
            label: "DocumentReference By Id",
            resource: `api/DocumentReference/:id`,
            body: ApiData["Document Ref"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: (
                  <span>
                    This request is using Bearer Token from folder{" "}
                    <a
                      target="_blank"
                      // href={`https://fhir-api.ethizo.com/#auth-info-9164262e-b291-4455-9e5c-13a5b18585ed`}
                      onClick={handleDocRef}
                      style={{
                        color: "darkorange",
                        cursor: "pointer",
                      }}
                    >
                      DocumentReference
                    </a>
                  </span>
                ),
              },
              label: "path variables",
              params: {
                id: 2883,
                // category: "34117-2",
              },
            },
          },
          {
            type: "API",
            method: "POST",
            label: "_search",
            resource: `api/DocumentReference/_search`,
            body: ApiData["Document Ref"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "Body",
              params: {
                patient: patientID,
                // _revinclude: "Provenance:target",
              },
            },
          },
        ],
      },
      {
        label: "Encounter",
        type: "heading",
        description: (
          <>
            This resource conforms to USCDI profile for Encounter - refer to{" "}
            <NavigateLink link="http://hl7.org/fhir/us/core/STU3.1.1/StructureDefinition-us-core-encounter.html">
              US Core Encounter Profile.
            </NavigateLink>{" "}
            Encounter resource response can be requested in JSON or XML format
            as per FHIR standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/encounter.html">
              Encounter - FHIR v4.0.1 (hl7.org)
            </NavigateLink>{" "}
            supports the following data for Encounter.
          </>
        ),
        links: [
          {
            type: "API",
            method: "GET",
            label: "Encounter",
            resource: `api/Encounter/:id`,
            body: ApiData.Encounter,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "path variables",
              params: {
                id: 3324,
              },
            },
          },
        ],
      },
      {
        label: "Goal",
        description: (
          <>
            This resource conforms to USCDI profile for Goal - refer to{" "}
            <NavigateLink link="http://hl7.org/fhir/us/core/STU3.1.1/StructureDefinition-us-core-goal.html">
              US Core Goal Profile.
            </NavigateLink>{" "}
            Goal response can be requested in JSON or XML format as per FHIR
            standard R4 version
            <NavigateLink link="http://hl7.org/fhir/R4/goal.html">
              Goal - FHIR v4.0.1 (hl7.org)
            </NavigateLink>{" "}
            supports the data element Patient Goals
          </>
        ),
        type: "heading",
        links: [
          {
            type: "API",
            method: "GET",
            label: "Search Goal",
            resource: `api/Goal?patient=${patientID}&_revinclude=Provenance:target`,
            body: ApiData.Goal,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PARAMS",
              params: {
                patient: patientID,
                _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "GET",
            label: "Goal By Id",
            resource: `api/Goal/:id`,
            body: ApiData.Goal,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PATH VARIABLES",
              params: {
                id: 2231,
                // _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "POST",
            label: "_search",
            resource: `api/Goal/_search`,
            body: ApiData.Goal,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "body",
              params: {
                patient: patientID,
                // _revinclude: "Provenance:target",
              },
            },
          },
        ],
      },
      {
        label: "Head Occipital-frontal Circumference",
        type: "heading",
        description: (
          <>
            This resource conforms to USCDI profile for Observation - refer to
            <NavigateLink link="http://hl7.org/fhir/us/core/STU3.1.1/StructureDefinition-head-occipital-frontal-circumference-percentile.html">
              US Core Pediatric Head Occipital-Frontal Circumference Percentile
              Observation
            </NavigateLink>{" "}
            Profile. Observation response can be requested in JSON or XML format
            as per FHIR standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/Observation.html">
              Observation - FHIR v4.0.1 (hl7.org)
            </NavigateLink>
            supports the data elements documented as Occipital-frontal Head
            Circumference Percentile.
          </>
        ),
        links: [
          {
            type: "API",
            method: "GET",
            label: "Search Head Occipital-frontal",
            resource: `api/Observation?patient=${patientID}&code=8289-1&_revinclude=Provenance:target`,
            body: ApiData["Head Occipital-frontal Circumference"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PARAMS",
              params: {
                patient: patientID,
                category: "vital-signs",
                date: "2022-08-31T12:32:00%2B00:00",
                code: "8289-1",
                _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "GET",
            label: "Observation By Id",
            resource: `api/Observation/:id`,
            body: ApiData["Head Occipital-frontal Circumference"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "path variables",
              params: {
                id: 3326,
                // _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "POST",
            resource: `api/Observation/_search`,
            label: "_search",
            body: ApiData["Head Occipital-frontal Circumference"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "body",
              params: {
                category: "clinical-notes",
                patient: patientID,
                code: "8289-1",
              },
            },
          },
        ],
      },
      {
        label: "Immunization",
        description: (
          <>
            This resource conforms to USCDI profile for Immunization - refer to
            <NavigateLink link="http://hl7.org/fhir/us/core/STU3.1.1/StructureDefinition-us-core-immunization.html">
              {" "}
              US Core Immunization Profile.
            </NavigateLink>{" "}
            Immunization response can be requested in JSON or XML format as per
            FHIR standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/immunization.html"></NavigateLink>
            supports the data elements documented as Immunization.
          </>
        ),
        type: "heading",
        links: [
          {
            type: "API",
            method: "GET",
            resource: `api/Immunization?patient=${patientID}&_revinclude=Provenance:target`,
            label: "Search Immunization",
            body: ApiData.Immunization,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PARAMS",
              params: {
                patient: patientID,
                _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "GET",
            label: "Immunization By Id",
            resource: `api/Immunization/:id`,
            body: ApiData.Immunization,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "path variables",
              params: {
                id: 471,
                // _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "POST",
            label: "_search",
            resource: `api/Immunization/_search`,
            body: ApiData.Immunization,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "body",
              params: {
                patient: patientID,
                // _revinclude: "Provenance:target",
              },
            },
          },
        ],
      },
      {
        label: "Laboratory Result Observation",
        description: (
          <>
            This resource conforms to USCDI profile for Observation - refer to
            <NavigateLink link="http://hl7.org/fhir/us/core/STU3.1.1/StructureDefinition-us-core-observation-lab.html">
              US Core Lab Result Observation
            </NavigateLink>{" "}
            Profile. Observation response can be requested in JSON or XML format
            as per FHIR standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/Observation.html">
              Observation - FHIR v4.0.1 (hl7.org)
            </NavigateLink>
            supports the data elements documented as Lab Result Observation.
          </>
        ),
        type: "heading",
        links: [
          {
            type: "API",
            resource: `api/Observation?patient=${patientID}&code=59576-9&_revinclude=Provenance:target`,
            method: "GET",
            label: "Search Laboratory Result Observation",
            body: ApiData["Laboratory Result Observation"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PARAMS",
              params: {
                patient: patientID,
                category: "lab",

                date: `2022-08-31T12:32:00%2B00:00`,
                code: `59576-9`,
                _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "GET",
            label: "Observation By Id",
            resource: `api/Observation/:id`,
            body: ApiData["Laboratory Result Observation"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "path variables",
              params: {
                id: 41469,
                // _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "POST",
            label: "_search",
            resource: `api/Observation/_search`,
            body: ApiData["Laboratory Result Observation"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "body",
              params: {
                category: "laboratory",
                patient: patientID,
                code: "77606-2",
              },
            },
          },
        ],
      },
      {
        label: "Location",
        type: "heading",
        description: (
          <>
            This resource conforms to USCDI profile for Location - refer to{" "}
            <NavigateLink link="http://hl7.org/fhir/us/core/STU3.1.1/StructureDefinition-us-core-location.html">
              US Core Location Profile.
            </NavigateLink>{" "}
            Location resource response can be requested in JSON or XML format as
            per FHIR standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/encounter.html">
              Encounter - FHIR v4.0.1 (hl7.org)
            </NavigateLink>{" "}
            supports the following data for Location.
          </>
        ),
        links: [
          {
            type: "API",
            method: "GET",
            resource: `api/Location/:id`,
            label: "Location",
            body: PatientApis.searchPatient,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "path variables",
              params: {
                id: 772,
                // _revinclude: "Provenance:target",
              },
            },
          },
        ],
      },
      {
        label: "MedicationRequest",
        description: (
          <>
            This resource conforms to USCDI profile for MedicationRequest -
            refer to{" "}
            <NavigateLink link="http://hl7.org/fhir/us/core/STU3.1.1/StructureDefinition-us-core-medicationrequest.html">
              US Core MedicationRequest
            </NavigateLink>{" "}
            Profile. MedicationRequest response can be requested in JSON or XML
            format as per FHIR standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/MedicationRequest.html">
              MedicationRequest - FHIR v4.0.1 (hl7.org)
            </NavigateLink>{" "}
            supports the following data elements documented as Medications.
          </>
        ),
        type: "heading",
        links: [
          {
            type: "API",
            method: "GET",
            resource: `api/MedicationRequest?patient=${patientID}&intent=proposal,plan,order,original-order,reflex-order,filler-order,instance-order,option&status=active,on,hold,cancelled,completed,entered-in-error,stopped,draft,unknown&_revinclude=Provenance:target`,
            label: "MedicationRequest By Patient Id",
            body: ApiData["Medication Req"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PARAMS",
              params: {
                patient: patientID,
                _include: "_include",
                intent:
                  "proposal,plan,order,original-order,reflex-order,filler-order,instance-order,option",
                status:
                  "active,on,hold,cancelled,completed,entered-in-error,stopped,draft,unknown",
                _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "GET",
            label: "MedicationRequest By Id",
            resource: `api/MedicationRequest/:id`,
            body: ApiData["Medication Req"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "path variables",
              params: {
                id: `5328777`,
                // _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "POST",
            label: "_search",
            resource: `api/MedicationRequest/_search`,
            body: ApiData["Medication Req"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "body",
              params: {
                patient: patientID,
                intent: "order",
              },
            },
          },
        ],
      },
      {
        label: "Observation Body Height",
        type: "heading",
        description: (
          <>
            This resource conforms to USCDI profile for Observation - refer to
            US Core Observation Profile. Observation response can be requested
            in JSON or XML format as per FHIR standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/Observation.html">
              Observation - FHIR v4.0.1 (hl7.org)
            </NavigateLink>{" "}
            supports the data elements documented as Body Height.
          </>
        ),
        links: [
          {
            type: "API",
            method: "GET",
            resource: `api/Observation?patient=${patientID}&code=8302-2&_revinclude=Provenance:target`,

            label: "Search Observation Body Height",
            body: ApiData["Observation Body Height"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PARAMS",
              params: {
                patient: patientID,
                category: "vital-signs",
                date: `2022-08-31T12:32:00%2B00:00`,
                code: `8302-2`,
                _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "GET",
            resource: `api/Observation/:id`,
            label: "Observation By Id",
            body: ApiData["Observation Body Height"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "path variables",
              params: {
                id: 33241,
                // _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "POST",
            label: "_search",
            resource: `api/Observation/_search`,
            body: ApiData["Observation Body Height"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "body",
              params: {
                patient: patientID,
                category: "vital-signs",
                code: "8302-2",
                // _revinclude: "Provenance:target",
              },
            },
          },
        ],
      },
      {
        label: "Observation Body Temperature",
        type: "heading",
        description: (
          <>
            This resource conforms to USCDI profile for Observation - refer to
            US Core Observation Profile. Observation response can be requested
            in JSON or XML format as per FHIR standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/Observation.html">
              Observation - FHIR v4.0.1 (hl7.org)
            </NavigateLink>{" "}
            supports the data elements documented as Body Temperature.
          </>
        ),
        links: [
          {
            type: "API",
            method: "GET",
            label: "Search Observation Body Temperature",
            resource: `api/Observation?patient=${patientID}&code=8310-5&_revinclude=Provenance:target`,
            body: ApiData["Body Temperature"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PARAMS",
              params: {
                patient: patientID,
                category: "vital-signs",
                date: `2022-08-31T12:32:00%2B00:00`,
                code: `8310-5`,
                _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "GET",
            label: "Observation By Id",
            resource: `api/Observation/:id`,
            body: ApiData["Body Temperature"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "path variables",
              params: {
                id: 41422,
              },
            },
          },
          {
            type: "API",
            method: "POST",
            label: "_search",
            resource: `api/Observation/_search`,
            body: ApiData["Body Temperature"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "body",
              params: {
                category: "vital-signs",
                patient: patientID,
                code: "8310-5",
              },
            },
          },
        ],
      },
      {
        label: "Observation Blood Pressure",
        type: "heading",
        description: (
          <>
            This resource conforms to USCDI profile for Observation - refer to
            US Core Observation Profile. Observation response can be requested
            in JSON or XML format as per FHIR standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/Observation.html">
              Observation - FHIR v4.0.1 (hl7.org)
            </NavigateLink>{" "}
            supports the data elements documented as Blood Pressure
            Systolic/Diastolic.
          </>
        ),
        links: [
          {
            type: "API",
            method: "GET",
            resource: `api/Observation?patient=${patientID}&code=85354-9&_revinclude=Provenance:target`,
            label: "Search Observation Blood Pressure",
            body: ApiData["Blood Pressure"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PARAMS",
              params: {
                patient: patientID,
                category: "vital-signs",
                date: `2022-08-31T12:32:00%2B00:00`,
                code: `8310-5`,
                _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "GET",
            label: "Observation By Id",
            body: ApiData["Blood Pressure"],
            resource: `api/Observation/:id`,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "path variables",
              params: {
                id: 41419,
              },
            },
          },
          {
            type: "API",
            method: "POST",
            label: "_search",
            resource: `api/Observation/_search`,
            body: ApiData["Blood Pressure"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "body",
              params: {
                category: "clinicial-notes",
                patient: patientID,
                code: "34321-9",
              },
            },
          },
        ],
      },
      {
        label: "Observation Body Weight",
        type: "heading",
        description: (
          <>
            This resource conforms to USCDI profile for Observation - refer to
            US Core Observation Profile. Observation response can be requested
            in JSON or XML format as per FHIR standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/Observation.html">
              Observation - FHIR v4.0.1 (hl7.org)
            </NavigateLink>{" "}
            supports the data elements documented as Heart Rate.
          </>
        ),
        links: [
          {
            type: "API",
            method: "GET",
            resource: `api/Observation?patient=${patientID}&code=29463-7&_revinclude=Provenance:target`,
            label: "Search Observation Body Weight",
            body: ApiData["Body Weight"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PARAMS",
              params: {
                patient: patientID,
                category: "vital-signs",
                date: `2022-08-31T12:32:00%2B00:00`,
                code: `8310-5`,
                _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "GET",
            label: "Observation By Id",
            resource: `api/Observation/:id`,
            body: ApiData["Body Weight"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "path variables",
              params: {
                id: 41433,
              },
            },
          },
          {
            type: "API",
            method: "POST",
            label: "_search",
            resource: `api/Observation/_search`,
            body: ApiData["Body Weight"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "body",
              params: {
                category: "laboratory",
                patient: patientID,
                code: "29463-2",
              },
            },
          },
        ],
      },
      {
        label: "Observation Heart Rate",
        type: "heading",
        description: (
          <>
            This resource conforms to USCDI profile for Observation - refer to
            US Core Observation Profile. Observation response can be requested
            in JSON or XML format as per FHIR standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/Observation.html">
              Observation - FHIR v4.0.1 (hl7.org)
            </NavigateLink>{" "}
            supports the data elements documented as Heart Rate.
          </>
        ),
        links: [
          {
            type: "API",
            method: "GET",
            resource: `api/Observation?patient=${patientID}&code=8867-4&_revinclude=Provenance:target`,
            label: "Search Observation Heart Rate",
            body: ApiData["Heart Rate"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PARAMS",
              params: {
                patient: patientID,
                category: "vital-signs",
                date: `2022-08-31T12:32:00%2B00:00`,
                code: `8310-5`,
                _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "GET",
            label: "Observation By Id",
            resource: `api/Observation/:id`,
            body: ApiData["Heart Rate"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "path variables",
              params: {
                id: 412241,
              },
            },
          },
          {
            type: "API",
            method: "POST",
            label: "_search",
            body: ApiData["Heart Rate"],
            resource: `api/Observation/_search`,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "body",
              params: {
                category: "vital-signs",
                patient: patientID,
                code: "8867-4",
              },
            },
          },
        ],
      },
      {
        label: "Observation Respiratory Rate",
        type: "heading",
        description: (
          <>
            This resource conforms to USCDI profile for Observation - refer to
            US Core Observation Profile. Observation response can be requested
            in JSON or XML format as per FHIR standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/Observation.html">
              Observation - FHIR v4.0.1 (hl7.org)
            </NavigateLink>{" "}
            supports the data elements documented as Respiratory Rate
          </>
        ),
        links: [
          {
            type: "API",
            method: "GET",
            label: "Search Observation Respiratory Rate",
            body: ApiData.RespirationRate,
            resource: `api/Observation?patient=${patientID}&code=9279-1&_revinclude=Provenance:target`,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PARAMS",
              params: {
                patient: patientID,
                category: "vital-signs",
                date: `2022-08-31T12:32:00%2B00:00`,
                code: `2313-5`,
                _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "GET",
            label: "Observation By Id",
            resource: `api/Observation/:id`,
            body: ApiData.RespirationRate,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "path variables",
              params: {
                id: 41443,
              },
            },
          },
          {
            type: "API",
            method: "POST",
            label: "_search",
            resource: `api/Observation/_search`,
            body: ApiData.RespirationRate,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "body",
              params: {
                category: "clinical-notes",
                patient: patientID,
                code: "9279-1",
              },
            },
          },
        ],
      },

      {
        label: "Organization",
        type: "heading",
        description: (
          <>
            This resource conforms to USCDI profile for Organization - refer to
            USCore{" "}
            <NavigateLink link="http://hl7.org/fhir/us/core/STU3.1.1/StructureDefinition-us-core-organization.html">
              Organization
            </NavigateLink>{" "}
            Profile. Organization response can be requested in JSON or XML
            format as per FHIR standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/organization.html">
              Organization - FHIR v4.0.1 (hl7.org)
            </NavigateLink>
            supports the following data elements:
          </>
        ),
        links: [
          {
            type: "API",
            method: "GET",
            resource: `api/Organization/:id`,
            label: "Organization",
            body: ApiData.Organization,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "Path variables",
              params: {
                id: 144,
              },
            },
          },
        ],
      },
      {
        type: "heading",
        label: "Patient",
        description: (
          <>
            This resource conforms to USCDI profile for Patient Demographics -
            refer to{" "}
            <NavigateLink link="https://hl7.org/fhir/us/core/stu3.1.1/StructureDefinition-us-core-patient.html">
              USCore Patient Profile.
            </NavigateLink>{" "}
            Patient resource response can be requested in JSON or XML format as
            per FHIR standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/patient.html">
              Patient - FHIR v4.0.1 (hl7.org)
            </NavigateLink>
            supports the following data for Patient
          </>
        ),
        links: [
          {
            type: "API",
            method: "GET",
            label: "Search Patient",
            resource: `api/Patient?_id=${patientID}&_revinclude=Provenance:target`,
            body: ApiData.Patient,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "98fc3578ed6af981235bb5892bcfead8",
              },
              label: "PARAMS",
              params: {
                name: "InfernoPatLName",
                dob: "1920-08-08",
                gender: "male",
                identifier: "31873981dsa7893218",
                _id: 22341,
                _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "GET",
            label: "Search Patient By ID",
            resource: `api/Patient/:id`,
            body: ApiData.Patient,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "98fc3578ed321344dd2bcfead8",
              },
              label: "path variables",
              params: {
                id: 33213,
                // dob: "1920-08-08",
                // gender: "male",
                // identifier: "31873981dsa7893218",

                // _id:patientID
                // _revinclude: "Provenance:target",
                // age: 24,
              },
            },
          },
          {
            type: "API",
            method: "POST",
            resource: "api/Patient/_search",
            label: "_search",
            body: ApiData.Patient,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "321321323213sads32132",
              },
              label: "BODY",
              params: {
                _id: patientID,
                // dob: "1920-08-08",
                // gender: "male",
                // identifier: "31873981dsa7893218",
                // _id:patientID
                // _revinclude: "Provenance:target",
                // age: 24,
              },
            },
          },
        ],
      },
      {
        label: "Procedure",
        description: (
          <>
            This resource conforms to USCDI profile for Procedure - refer to{" "}
            <NavigateLink link="http://hl7.org/fhir/us/core/STU3.1.1/StructureDefinition-us-core-procedure.html">
              US Core Procedure Profile.
            </NavigateLink>{" "}
            Procedure response can be requested in JSON or XML format as per
            FHIR standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/Procedure.html">
              Procedure - FHIR v4.0.1 (hl7.org)
            </NavigateLink>{" "}
            supports the data elements documented as Procedures.
          </>
        ),
        type: "heading",
        links: [
          {
            type: "API",
            method: "GET",
            resource: `api/Procedure?patient=${patientID}`,
            label: "Search Procedure",
            body: ApiData.Procedure,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PARAMS",
              params: {
                patient: patientID,
                date: `2022-08-31T12:32:00%2B00:00`,
                _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "GET",
            label: "Procedure By Id",
            resource: `api/Procedure/:id`,
            body: ApiData.Procedure,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "path variables",
              params: {
                id: 556,
              },
            },
          },
          {
            type: "API",
            method: "POST",
            label: "_search",
            resource: `api/Procedure/_search`,
            body: ApiData.Goal,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "body",
              params: {
                patient: patientID,
                // _revinclude: "Provenance:target",
              },
            },
          },
          // {
          //   type: "API",
          //   method: "POST",
          //   label: "_search",
          //   body: ApiData.Procedure,
          //   apiDetail: {
          //     Authorization: {
          //       type: "Bearer Token",
          //       token: "<token>",
          //     },
          //     label: "body",
          //     params: {
          //       patient: patientID,
          //       _revinclude: "Provenance:target",
          //     },
          //   },
          // },
        ],
      },
      {
        label: "Practitioner",
        type: "heading",
        description: (
          <>
            This resource conforms to USCDI profile for Practitioner - refer to
            <NavigateLink link="http://hl7.org/fhir/us/core/STU3.1.1/StructureDefinition-us-core-practitioner.html">
              USCore Practitioner
            </NavigateLink>{" "}
            Profile. Practitioner response can be requested in JSON or XML
            format as per FHIR standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/practitioner.html">
              Practitioner - FHIR v4.0.1 (hl7.org)
            </NavigateLink>{" "}
            supports the following data elements:
          </>
        ),
        links: [
          {
            type: "API",
            method: "GET",
            label: "Practitioner",
            resource: `api/Practitioner/:id`,
            body: ApiData.Practitioner,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "path variables",
              params: {
                id: 14490,
              },
            },
          },
        ],
      },
      {
        label: "Provenance",
        type: "heading",
        description: (
          <>
            This resource conforms to USCDI profile for Provenance - refer to{" "}
            <NavigateLink link="http://hl7.org/fhir/us/core/STU3.1.1/StructureDefinition-us-core-provenance.html">
              US Core Provenance
            </NavigateLink>{" "}
            Profile. Provenance response can be requested in JSON or XML format
            as per FHIR standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/Provenance.html">
              Provenance - FHIR v4.0.1 (hl7.org)
            </NavigateLink>{" "}
            supports the following data elements:
          </>
        ),
        links: [
          {
            type: "API",
            method: "GET",
            label: "Provenance",
            resource: `api/Provenance/:id`,
            body: ApiData.Provenance,
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PARAMS",
              params: {
                id: 1332,
              },
            },
          },
        ],
      },
      {
        label: "Pulse Oximetry",
        type: "heading",
        description: (
          <>
            This resource conforms to USCDI profile for Observation - refer to
            <NavigateLink link="http://hl7.org/fhir/us/core/STU3.1.1/StructureDefinition-us-core-pulse-oximetry.html">
              US Core Pulse Oximetry Observation
            </NavigateLink>{" "}
            Profile. Observation response can be requested in JSON or XML format
            as per FHIR standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/Observation.html">
              Observation - FHIR v4.0.1 (hl7.org)
            </NavigateLink>
            supports the data elements documented as Pulse Oximetry.
          </>
        ),
        links: [
          {
            type: "API",
            method: "GET",
            resource: `api/Observation?patient=${patientID}&code=2708-6`,
            label: "Search Pulse Oximetry",
            body: ApiData["Pulse Oximetry"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PARAMS",
              params: {
                patient: patientID,
                category: "vital-signs",
                date: `2022-08-31T12:32:00%2B00:00`,
                code: `3321-5`,
                _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "GET",
            label: "Observation By Id",
            resource: `api/Observation/:id`,
            body: ApiData["Pulse Oximetry"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "path variables",
              params: {
                id: 22314,
              },
            },
          },
          {
            type: "API",
            method: "POST",
            resource: `api/Observation/_search`,
            label: "_search",
            body: ApiData["Pulse Oximetry"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "body",
              params: {
                category: "laboratory",
                patient: patientID,
                code: "2708-6",
              },
            },
          },
        ],
      },

      {
        label: "Smoking Status Observation",
        description: (
          <>
            This resource conforms to USCDI profile for Observation - refer to
            <NavigateLink link="http://hl7.org/fhir/us/core/STU3.1.1/StructureDefinition-us-core-smokingstatus.html">
              US Core Observation
            </NavigateLink>{" "}
            Profile. Observation response can be requested in JSON or XML format
            as per FHIR standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/Observation.html">
              Observation - FHIR v4.0.1 (hl7.org)
            </NavigateLink>
            supports the data elements documented as Smoking Status.
          </>
        ),
        type: "heading",
        links: [
          {
            type: "API",
            method: "GET",
            label: "Search Smoking Status",
            resource: `api/Observation?patient=${patientID}&code=72166-2`,
            body: ApiData["Smoking Status"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PARAMS",
              params: {
                patient: patientID,
                category: "vital-signs",
                date: `2022-08-31T12:32:00%2B00:00`,
                code: `72166-2`,
                _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "GET",
            label: "Observation By Id",
            resource: `api/Observation/:id`,
            body: ApiData["Smoking Status"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "path variables",
              params: {
                id: "528",
              },
            },
          },
          {
            type: "API",
            method: "POST",
            label: "_search",
            resource: `api/Observation/_search`,
            body: ApiData["Smoking Status"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "body",
              params: {
                category: "vital-signs",
                patient: patientID,
                code: "72166-2",
              },
            },
          },
        ],
      },
      {
        label: "Weight For Height Observation",
        description: (
          <>
            This resource conforms to USCDI profile for Observation - refer to
            <NavigateLink link="http://hl7.org/fhir/us/core/STU3.1.1/StructureDefinition-pediatric-weight-for-height.html">
              US Core Pediatric Weight For Height Observation
            </NavigateLink>{" "}
            Profile. Observation response can be requested in JSON or XML format
            as per FHIR standard R4 version{" "}
            <NavigateLink link="http://hl7.org/fhir/R4/Observation.html">
              Observation - FHIR v4.0.1 (hl7.org)
            </NavigateLink>
            supports the data elements documented as Weight for Length
            Percentile.
          </>
        ),
        type: "heading",
        links: [
          {
            type: "API",
            method: "GET",
            resource: `api/Observation?patient=${patientID}&code=77606-2&_revinclude=Provenance:target`,
            label: "Search Weight For Height Observation",
            body: ApiData["Pediatric Weight for height observation"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "PARAMS",
              params: {
                patient: patientID,
                category: "vital-signs",
                date: `2022-08-31T12:32:00%2B00:00`,
                code: `77606-2`,
                _revinclude: "Provenance:target",
              },
            },
          },
          {
            type: "API",
            method: "GET",
            resource: `api/Observation/:id`,
            label: "Observation By Id",
            body: ApiData["Pediatric Weight for height observation"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "path variables",
              params: {
                id: 41456,
              },
            },
          },
          {
            type: "API",
            method: "POST",
            label: "_search",
            resource: `api/Observation/_search`,
            body: ApiData["Pediatric Weight for height observation"],
            apiDetail: {
              Authorization: {
                type: "Bearer Token",
                token: "<token>",
              },
              label: "body",
              params: {
                category: "vital-signs",
                patient: patientID,
                code: "33321-5",
              },
            },
          },
        ],
      },
    ],
  },
  {
    label: "Errors and Exceptions",
    data: <ErrorsExceptions />,
  },
  {
    label: "Terms & Conditions",
    data: <TermsAndCondtions />
  }
];
function App() {
  return (
    <>
      <NavBar />
      <MainInfo leftMenu={Info} />
      <LeftMenu leftMenu={Info} />
    </>
  );
}

export default App;
