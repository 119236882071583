const PatientApis = {
  searchPatient: {
    resourceType: "Bundle",
    id: "31400f28-8371-4cbe-bd6b-d7f5f647ea04",
    meta: {
      lastUpdated: "2022-10-20T06:54:17.5234165+00:00",
    },
    entry: [
      {
        resource: {
          resourceType: "Patient",
          id: "3260",
          meta: {
            versionId: "1",
            lastUpdated: "2022-10-20T06:54:17.531352+00:00",
            source: "#iLFSV7OLv0KF8dmQ",
            profile: [
              "http://hl7.org/fhir/us/core/StructureDefinition/us-core-patient",
            ],
          },
          text: {
            status: "generated",
            div: '<div xmlns="http://www.w3.org/1999/xhtml">Generated by <a href="https://github.com/synthetichealth/synthea">Synthea</a>.Version identifier: v2.6.1-3-g50f4f58f\n .   Person seed: 4286870567281389426  Population seed: 3</div>',
          },
          extension: [
            {
              extension: [
                {
                  url: "ombCategory",
                  valueCoding: {
                    system: "urn:oid:2.16.840.1.113883.6.238",
                    code: "2106-3",
                    display: "White",
                  },
                },
                {
                  url: "text",
                  valueString: "White",
                },
              ],
              url: "http://hl7.org/fhir/us/core/StructureDefinition/us-core-race",
            },
            {
              extension: [
                {
                  url: "ombCategory",
                  valueCoding: {
                    system: "urn:oid:2.16.840.1.113883.6.238",
                    code: "2135-2",
                    display: "Hispanic or Latino",
                  },
                },
                {
                  url: "text",
                  valueString: "Hispanic or Latino",
                },
              ],
              url: "http://hl7.org/fhir/us/core/StructureDefinition/us-core-ethnicity",
            },
            {
              url: "http://hl7.org/fhir/us/core/StructureDefinition/us-core-birthsex",
              valueCode: "M",
            },
            {
              url: "http://hl7.org/fhir/StructureDefinition/patient-birthPlace",
              valueAddress: {
                city: "Atlanta",
                state: "GA",
                country: "USA",
              },
            },
          ],
          identifier: [
            {
              system: "https://github.com/synthetichealth/synthea",
              value: "6d9d43aba2f743f987e2a92e5f3d88dc",
            },
            {
              type: {
                coding: [
                  {
                    system: "http://terminology.hl7.org/CodeSystem/v2-0203",
                    code: "MR",
                    display: "Medical Record Number",
                  },
                ],
                text: "Medical Record Number",
              },
              system: "http://hospital.smarthealthit.org",
              value: "11192",
            },
          ],
          name: [
            {
              use: "official",
              text: "McKay,Jim",
              family: "McKay",
              given: ["Jim", "M"],
              prefix: ["Mr."],
              suffix: ["Mr."],
            },
            {
              use: "old",
              text: "Micheal,John",
              family: "Micheal",
              given: ["John"],
              prefix: ["Mr."],
              suffix: ["Mr."],
            },
          ],
          telecom: [
            {
              system: "phone",
              value: "9292385566",
              use: "mobile",
            },
            {
              system: "phone",
              value: "7034445525",
              use: "home",
            },
            {
              system: "email",
              value: "jim.mckay@gmail.com",
              use: "work",
            },
          ],
          gender: "male",
          birthDate: "1979-08-08",
          address: [
            {
              use: "home",
              type: "postal",
              text: "114 Cascade Pkwy Apt 6e Atlanta GA 30311",
              line: ["114 Cascade Pkwy", "Apt 6e"],
              city: "Atlanta",
              state: "GA",
              postalCode: "30311",
              country: "USA",
              period: {
                start: "2022-10-20",
              },
            },
            {
              use: "old",
              type: "postal",
              text: "114 Cascade Pkwy Apt 6e Atlanta GA 30311",
              line: ["114 Cascade Pkwy", "Apt 6e"],
              city: "Atlanta",
              state: "GA",
              postalCode: "30311",
              country: "USA",
              period: {
                start: "2022-10-20",
              },
            },
          ],
          maritalStatus: {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/v3-MaritalStatus",
                code: "UNK",
                display: "unknown",
              },
            ],
          },
          communication: [
            {
              language: {
                coding: [
                  {
                    system: "urn:ietf:bcp:47",
                    code: "English",
                  },
                ],
              },
              preferred: true,
            },
          ],
        },
      },
    ],
  },

  searchPatientById: {
    resourceType: "Patient",
    id: "3260",
    meta: {
      versionId: "1",
      lastUpdated: "2022-10-20T07:06:22.4425972+00:00",
      source: "#iLFSV7OLv0KF8dmQ",
      profile: [
        "http://hl7.org/fhir/us/core/StructureDefinition/us-core-patient",
      ],
    },
    text: {
      status: "generated",
      div: '<div xmlns="http://www.w3.org/1999/xhtml">Generated by <a href="https://github.com/synthetichealth/synthea">Synthea</a>.Version identifier: v2.6.1-3-g50f4f58f\n .   Person seed: 4286870567281389426  Population seed: 3</div>',
    },
    extension: [
      {
        extension: [
          {
            url: "ombCategory",
            valueCoding: {
              system: "urn:oid:2.16.840.1.113883.6.238",
              code: "2106-3",
              display: "White",
            },
          },
          {
            url: "text",
            valueString: "White",
          },
        ],
        url: "http://hl7.org/fhir/us/core/StructureDefinition/us-core-race",
      },
      {
        extension: [
          {
            url: "ombCategory",
            valueCoding: {
              system: "urn:oid:2.16.840.1.113883.6.238",
              code: "2135-2",
              display: "Hispanic or Latino",
            },
          },
          {
            url: "text",
            valueString: "Hispanic or Latino",
          },
        ],
        url: "http://hl7.org/fhir/us/core/StructureDefinition/us-core-ethnicity",
      },
      {
        url: "http://hl7.org/fhir/us/core/StructureDefinition/us-core-birthsex",
        valueCode: "M",
      },
      {
        url: "http://hl7.org/fhir/StructureDefinition/patient-birthPlace",
        valueAddress: {
          city: "Atlanta",
          state: "GA",
          country: "USA",
        },
      },
    ],
    identifier: [
      {
        system: "https://github.com/synthetichealth/synthea",
        value: "6d9d43aba2f743f987e2a92e5f3d88dc",
      },
      {
        type: {
          coding: [
            {
              system: "http://terminology.hl7.org/CodeSystem/v2-0203",
              code: "MR",
              display: "Medical Record Number",
            },
          ],
          text: "Medical Record Number",
        },
        system: "http://hospital.smarthealthit.org",
        value: "11192",
      },
    ],
    name: [
      {
        use: "official",
        text: "McKay,Jim",
        family: "McKay",
        given: ["Jim", "M"],
        prefix: ["Mr."],
        suffix: ["Mr."],
      },
      {
        use: "old",
        text: "Micheal,John",
        family: "Micheal",
        given: ["John"],
        prefix: ["Mr."],
        suffix: ["Mr."],
      },
    ],
    telecom: [
      {
        system: "phone",
        value: "9292385566",
        use: "mobile",
      },
      {
        system: "phone",
        value: "7034445525",
        use: "home",
      },
      {
        system: "email",
        value: "jim.mckay@gmail.com",
        use: "work",
      },
    ],
    gender: "male",
    birthDate: "1979-08-08",
    address: [
      {
        use: "home",
        type: "postal",
        text: "114 Cascade Pkwy Apt 6e Atlanta GA 30311",
        line: ["114 Cascade Pkwy", "Apt 6e"],
        city: "Atlanta",
        state: "GA",
        postalCode: "30311",
        country: "USA",
        period: {
          start: "2022-10-20",
        },
      },
      {
        use: "old",
        type: "postal",
        text: "114 Cascade Pkwy Apt 6e Atlanta GA 30311",
        line: ["114 Cascade Pkwy", "Apt 6e"],
        city: "Atlanta",
        state: "GA",
        postalCode: "30311",
        country: "USA",
        period: {
          start: "2022-10-20",
        },
      },
    ],
    maritalStatus: {
      coding: [
        {
          system: "http://terminology.hl7.org/CodeSystem/v3-MaritalStatus",
          code: "UNK",
          display: "unknown",
        },
      ],
    },
    communication: [
      {
        language: {
          coding: [
            {
              system: "urn:ietf:bcp:47",
              code: "English",
            },
          ],
        },
        preferred: true,
      },
    ],
  },

  _search: {
    resourceType: "Bundle",
    id: "cdf9be0c-9d66-4200-b6ee-4760ac61ea70",
    meta: {
      lastUpdated: "2022-10-20T07:09:12.7383566+00:00",
    },
    entry: [
      {
        resource: {
          resourceType: "Patient",
          id: "3260",
          meta: {
            versionId: "1",
            lastUpdated: "2022-10-20T07:09:12.7383868+00:00",
            source: "#iLFSV7OLv0KF8dmQ",
            profile: [
              "http://hl7.org/fhir/us/core/StructureDefinition/us-core-patient",
            ],
          },
          text: {
            status: "generated",
            div: '<div xmlns="http://www.w3.org/1999/xhtml">Generated by <a href="https://github.com/synthetichealth/synthea">Synthea</a>.Version identifier: v2.6.1-3-g50f4f58f\n .   Person seed: 4286870567281389426  Population seed: 3</div>',
          },
          extension: [
            {
              extension: [
                {
                  url: "ombCategory",
                  valueCoding: {
                    system: "urn:oid:2.16.840.1.113883.6.238",
                    code: "2106-3",
                    display: "White",
                  },
                },
                {
                  url: "text",
                  valueString: "White",
                },
              ],
              url: "http://hl7.org/fhir/us/core/StructureDefinition/us-core-race",
            },
            {
              extension: [
                {
                  url: "ombCategory",
                  valueCoding: {
                    system: "urn:oid:2.16.840.1.113883.6.238",
                    code: "2135-2",
                    display: "Hispanic or Latino",
                  },
                },
                {
                  url: "text",
                  valueString: "Hispanic or Latino",
                },
              ],
              url: "http://hl7.org/fhir/us/core/StructureDefinition/us-core-ethnicity",
            },
            {
              url: "http://hl7.org/fhir/us/core/StructureDefinition/us-core-birthsex",
              valueCode: "M",
            },
            {
              url: "http://hl7.org/fhir/StructureDefinition/patient-birthPlace",
              valueAddress: {
                city: "Atlanta",
                state: "GA",
                country: "USA",
              },
            },
          ],
          identifier: [
            {
              system: "https://github.com/synthetichealth/synthea",
              value: "6d9d43aba2f743f987e2a92e5f3d88dc",
            },
            {
              type: {
                coding: [
                  {
                    system: "http://terminology.hl7.org/CodeSystem/v2-0203",
                    code: "MR",
                    display: "Medical Record Number",
                  },
                ],
                text: "Medical Record Number",
              },
              system: "http://hospital.smarthealthit.org",
              value: "11192",
            },
          ],
          name: [
            {
              use: "official",
              text: "McKay,Jim",
              family: "McKay",
              given: ["Jim", "M"],
              prefix: ["Mr."],
              suffix: ["Mr."],
            },
            {
              use: "old",
              text: "Micheal,John",
              family: "Micheal",
              given: ["John"],
              prefix: ["Mr."],
              suffix: ["Mr."],
            },
          ],
          telecom: [
            {
              system: "phone",
              value: "9292385566",
              use: "mobile",
            },
            {
              system: "phone",
              value: "7034445525",
              use: "home",
            },
            {
              system: "email",
              value: "jim.mckay@gmail.com",
              use: "work",
            },
          ],
          gender: "male",
          birthDate: "1979-08-08",
          address: [
            {
              use: "home",
              type: "postal",
              text: "114 Cascade Pkwy Apt 6e Atlanta GA 30311",
              line: ["114 Cascade Pkwy", "Apt 6e"],
              city: "Atlanta",
              state: "GA",
              postalCode: "30311",
              country: "USA",
              period: {
                start: "2022-10-20",
              },
            },
            {
              use: "old",
              type: "postal",
              text: "114 Cascade Pkwy Apt 6e Atlanta GA 30311",
              line: ["114 Cascade Pkwy", "Apt 6e"],
              city: "Atlanta",
              state: "GA",
              postalCode: "30311",
              country: "USA",
              period: {
                start: "2022-10-20",
              },
            },
          ],
          maritalStatus: {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/v3-MaritalStatus",
                code: "UNK",
                display: "unknown",
              },
            ],
          },
          communication: [
            {
              language: {
                coding: [
                  {
                    system: "urn:ietf:bcp:47",
                    code: "English",
                  },
                ],
              },
              preferred: true,
            },
          ],
        },
      },
    ],
  },
};
export default PatientApis;
